import {Action, createReducer, on} from "@ngrx/store";
import {AccountsState} from "./accounts.state";
import {
    accountCreate,
    accountCreateFailure,
    accountCreateSuccess,
    accountForgotPassword,
    accountForgotPasswordFailure,
    accountForgotPasswordSuccess,
    accountResetError,
    accountResetPassword,
    accountResetPasswordFailure,
    accountResetPasswordSuccess,
    accountSendCode,
    accountSendCodeFailure,
    accountSendCodeSuccess,
    accountVerify,
    accountVerifyCode,
    accountVerifyCodeFailure,
    accountVerifyCodeSuccess,
    accountVerifyFailure,
    accountVerifySuccess,
    accountValidateCgu,
    accountValidateCguSuccess,
    accountValidateCguFailure,
    accountUpdateUser,
    accountUpdateUserSuccess,
    accountUpdateUserFailure,
    accountValidatePopupVersion,
    accountValidatePopupVersionSuccess,
    accountValidatePopupVersionFailure,
    accountEmailVerificationNeeded
} from "./accounts.actions";
import {AccountResponseErrorCodes} from "./accounts.models";
import {UserInfo} from "../../../../core/auth/auth.models";


const initialState: AccountsState = {
  create: {
    loading: false,
    error: null,
    email: null,
    user: null
  },
  update: {
    loading: false,
    error: null,
    user: null
  },
};

const reducer = createReducer(initialState,

  // verify email
  on(accountVerify, (state, action) => ({
    ...state,
    create: {
      loading: true,
      email: null,
      error: null,
      user: null,
    }
  })),
  on(accountVerifySuccess, (state, action) => ({
    ...state,
    create: {
      loading: false,
      email: action.email,
      error: null,
      user: null,
    }
  })),
  on(accountVerifyFailure, (state, action) => ({
    ...state,
    create: {
      user: null,
      loading: false,
      email: null,
      error: {
        error: action.error,
        errorCode: action.errorCode
      }
    }
  })),

  // create account
  on(accountCreate, (state, action) => ({
    ...state,
    create: {
      ...state.create,
      loading: true,
      error: null,
      user: null,
    }
  })),
  on(accountCreateSuccess, (state, action) => ({
    ...state,
    create: {
      ...state.create,
      loading: false,
      error: null,
      user: action.user
    }
  })),

  // email verification
    on(accountEmailVerificationNeeded, (state, action) => ({
      ...state,
      create: {
        ...state.create,
        loading: false,
        error: null,
        email: action.user.email,
        user: action.user,
        periodicVerification: true
      }
    })),
  // forgot/reset password and send/verify code
  on(accountForgotPassword, accountResetPassword, accountSendCode, accountVerifyCode,  (state, action) => ({
    ...state,
    create: {
      ...state.create,
      loading: true,
      error: null,
    }
  })),
  on(accountForgotPasswordSuccess, accountResetPasswordSuccess, accountSendCodeSuccess, accountVerifyCodeSuccess, (state, action) => ({
    ...state,
    create: {
      ...state.create,
      loading: false,
      error: null
    }
  })),
  on(accountCreateFailure, accountForgotPasswordFailure, accountResetPasswordFailure, accountSendCodeFailure, accountVerifyCodeFailure, (state, action) => ({
    ...state,
    create: {
      ...state.create,
      loading: false,
      error: {
        error: action.error,
        errorCode: action.errorCode
      }
    }
  })),


  on(accountResetError, (state, action) => ({
    ...state,
    create: {
      ...state.create,
      error: null
    }
  })),

    // validate cgu
    on(accountValidateCgu, (state, action) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
        }
    })),
    on(accountValidateCguSuccess, (state, action) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
        }
    })),
    on(accountValidateCguFailure, (state, action) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            error: {
                error: action.error,
                errorCode: action.errorCode
            }
        }
    })),

    // validate popup version
    on(accountValidatePopupVersion, (state, action) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
        }
    })),
    on(accountValidatePopupVersionSuccess, (state, action) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
        }
    })),
    on(accountValidatePopupVersionFailure, (state, action) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            error: {
                error: action.error,
                errorCode: action.errorCode
            }
        }
    })),

    // update user
    on(accountUpdateUser, (state, action) => ({
        ...state,
        update: {
            ...state.update,
            loading: true,
        }
    })),
    on(accountUpdateUserSuccess, (state, action) => ({
        ...state,
        update: {
            ...state.update,
            loading: false,
        }
    })),
    on(accountUpdateUserFailure, (state, action) => ({
        ...state,
        update: {
            ...state.update,
            loading: false,
            error: {
                error: action.error,
                errorCode: action.errorCode
            }
        }
    })),
);





export function accountsReducer(
  state: AccountsState | undefined,
  action: Action
): AccountsState {
  return reducer(state, action);
}
