import { authLogout, authResetControledUser } from './../../../../core/auth/auth.actions';
import {
  loadPatrimoniesListAction,
  loadPatrimoniesListSuccessAction,
  loadPatrimoniesListFailureAction,
  loadPatrimonyDetailsAction,
  loadPatrimonyDetailsSuccessAction,
  loadPatrimonyDetailsFailureAction,
  loadPatrimonyCertificatsAction,
  loadPatrimonyCertificatsSuccessAction,
  loadPatrimonyCertificatsFailureAction,
  resetPatrimonyDetails,
  loadPatrimonyDocumentsFailureAction,
  loadPatrimonyDocumentsSuccessAction,
  loadPatrimonyDocumentsAction,
  loadPatrimonyDocumentAction,
  loadPatrimonyDocumentSuccessAction,
  loadPatrimonyDocumentFailureAction,
  loadPatrimonyCertificateDocumentAction,
  loadPatrimonyCertificateDocumentSuccessAction,
  loadPatrimonyCertificateDocumentFailureAction, resetPatrimoniesList, patrimonyFavoriteAction
} from './patrimonies.actions';
import { PatrimoniesState } from './patrimonies.models';
import { createReducer, on, Action } from '@ngrx/store';

export const initialState: PatrimoniesState = {
  list: {
    query: null,
    data: null,
    loading: false,
    actionLoading: false,
    error: null,
  },
  details: {
    certificatesView: false,
    patrimony: null,
    documentsQuery: null,
    documents: null,
    certificatsQuery: null,
    certificats: null,
    loading: false,
    error: null
  },
  certificateDetail:{
    document : null,
    loading : false,
    error: null,
  },
  documentDetail:{
    document : null,
    loading : false,
    error: null,
  },
};

const reducer = createReducer(
  initialState,
  on(loadPatrimoniesListAction, (state, action) => ({
    ...state,
    ...{
      list: {
        query: action,
        data: initialState.list.data,
        loading: true,
        actionLoading: false,
        error: null,
      }
    }
  })),

  on(loadPatrimoniesListSuccessAction, (state, action) => ({
    ...state,
    ...{
      list: {
        query: state.list.query,
        data: action.result,
        loading: false,
        actionLoading: false,
        error: null,
      }
    }
  })),

  on(loadPatrimoniesListFailureAction, (state, action) => ({
    ...state,
    ...{
      list: {
        query: initialState.list.query,
        data: initialState.list.data,
        loading: false,
        actionLoading: false,
        error: action.error,
      }
    }
  })),

  on(resetPatrimoniesList, (state, action) => ({
    ...state,
    ...{
      list: {
        ...initialState.list
      }
    }
  })),

  // patrimony details reducers
  on(loadPatrimonyDetailsAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        patrimony: initialState.details.patrimony,
        loading: true,
        error: null,
      }
    }
  })),

  on(loadPatrimonyDetailsSuccessAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        patrimony: action.result,
        // loading: false,
        error: null,
      }
    }
  })),
  on(loadPatrimonyDetailsFailureAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        patrimony: initialState.details.patrimony,
        loading: false,
        error: action.error,
      }
    }
  })),
  on(resetPatrimonyDetails, (state, action) => ({
    ...state,
    ...{
      details: {
          ...state.details,
          patrimony: initialState.details.patrimony,
          documents: initialState.details.documents,
          certificats: initialState.details.certificats,
          loading: initialState.details.loading,
          error: initialState.details.error,
      }
    }
  })),

  // patrimony certificats
  on(loadPatrimonyCertificatsAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        certificatesView: true,
        certificatsQuery: action,
        certificats: initialState.details.certificats,
        loading: true,
        error: null,
      }
    }
  })),

  on(loadPatrimonyCertificatsSuccessAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        certificats: action.result,
        loading: false,
        error: null,
      }
    }
  })),

  on(loadPatrimonyCertificatsFailureAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        certificatsQuery: initialState.details.certificatsQuery,
        certificats: initialState.details.certificats,
        loading: false,
        error: action.error,
      }
    }
  })),

  // patrimony documents
  on(loadPatrimonyDocumentsAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        certificatesView: false,
        documentsQuery: action,
        documents: initialState.details.documents,
        loading: true,
        error: null,
      }
    }
  })),

  on(loadPatrimonyDocumentsSuccessAction, (state, action) => ({
    ...state,
    ...{
        details: {
        ...state.details,
        documents: action.result,
        loading: false,
        error: null,
      }
    }
  })),
  on(loadPatrimonyDocumentsFailureAction, (state, action) => ({
    ...state,
    ...{
        details: {
        ...state.details,
        documentsQuery: initialState.details.documentsQuery,
        documents: initialState.details.documents,
        loading: false,
        error: action.error,
      }
    }
  })),

  // patrimony document
  on(loadPatrimonyDocumentAction, (state, action) => ({
    ...state,
    ...{
      documentDetail: {
        document: initialState.documentDetail.document,
        loading: true,
        error: null,
      }
    }
  })),
  on(loadPatrimonyDocumentSuccessAction, (state, action) => ({
    ...state,
    ...{
      documentDetail: {
        document: action.result,
        loading: false,
        error: null,
      }
    }
  })),

  on(loadPatrimonyDocumentFailureAction, (state, action) => ({
    ...state,
    ...{
      documentDetail: {
        document: state.documentDetail.document,
        loading: false,
        error: action.error,
      }
    }
  })),

  // patrimony certificat document
  on(loadPatrimonyCertificateDocumentAction, (state, action) => ({
    ...state,
    ...{
      certificateDetail: {
        document: initialState.certificateDetail.document,
        loading: true,
        error: null,
      }
    }
  })),

  on(loadPatrimonyCertificateDocumentSuccessAction, (state, action) => ({
    ...state,
    ...{
      certificateDetail: {
        document: action.result,
        loading: false,
        error: null,
      }
    }
  })),

  on(loadPatrimonyCertificateDocumentFailureAction, (state, action) => ({
    ...state,
    ...{
      certificateDetail: {
        document: state.certificateDetail.document,
        loading: false,
        error: action.error,
      }
    }
  })),

  on(authLogout,authResetControledUser, (state, action) => initialState),

    //favorite actions
    on(patrimonyFavoriteAction, (state, action) => ({
      ...state,
      ...{
        list: {
          ...state.list,
          actionLoading: false,
          data: {
            ...state.list.data,
            data: [
                ...state.list.data.data
                    // On met à jour la valeur "favorite" de la ligne concernée pour mettre à jour l'affichage
                    .map(p => p.id !== action.patrimonyId ? {...p} : {...p, favorite: action.isFavorite})
            ]
          }
        }
      }
    }))

);

export function patrimoniesReducer(
  state: PatrimoniesState | undefined,
  action: Action
): PatrimoniesState {
  return reducer(state, action);
}
