import {Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';

import { StatusItem } from "./status-select.models";

@Component({
  selector: 'status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss'],
})
export class StatusSelectComponent implements OnInit, OnChanges {
    @Input() statusList: StatusItem[] = [];
    @Input() value: string = 'ALL';
    @Output() onSelectionChanged = new EventEmitter<any>();
    status: StatusItem | null = null;
    showIcons: boolean = true;

    constructor() { }

    ngOnInit() {
        this.showIcons = this.statusList.reduce((acc: boolean, status: StatusItem) => acc && !!status.icon, true);
    }

    getStatusList() {
        return this.statusList.filter(s => !s.hideFromSelect)
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { value } = changes;

        if (value) {
            const _value = value.currentValue;
            this.status = this.statusList.find((status: StatusItem) => status.value == _value);
        }
    }

    onStatusClick(status: StatusItem | null): void {
        this.status = status;
        this.onSelectionChanged.emit(status || { value: 'ALL' });
    }

}
