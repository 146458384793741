import {UserItem, UserItemDTO, UserSort, UserStatuses} from './users.models';
import { createAction, props } from '@ngrx/store';
import {AcPaginatedHTTPResponse, ListActionProps} from "../../../../core/http/http-response.models";

export const loadUsersListAction = createAction('[Users] Load list', props<ListActionProps<UserSort, UserStatuses>>());
export const loadUsersListSuccessAction = createAction('[Users] Load list success', props<{ result: AcPaginatedHTTPResponse<UserItem> }>());
export const loadUsersListFailureAction = createAction('[Users] Load list failure', props<{ error: any }>());

export const getUserAction = createAction('[Users] Get user', props<{ userId: number }>());
export const getUserSuccessAction = createAction('[Users] Get user success', props<{ result: UserItem }>());
export const getUserFailureAction = createAction('[Users] Get user failure', props<{ error: any }>());

export const createUserAction = createAction('[Users] create user', props<{ data: UserItemDTO }>());
export const createUserSuccessAction = createAction('[Users] create user success', props<{ result: UserItem }>());
export const createUserFailureAction = createAction('[Users] create user failure', props<{ error: any }>());

export const updateUserAction = createAction('[Users] Update user', props<{ userId: number, data: UserItemDTO }>());
export const updateUserSuccessAction = createAction('[Users] Update user success', props<{ result: UserItem }>());
export const updateUserFailureAction = createAction('[Users] Update user failure', props<{ error: any }>());

export const notifyUserAction = createAction('[Users] Notify user', props<{ userId: number, data: UserItemDTO }>());
export const notifyUserSuccessAction = createAction('[Users] Notify user success');
export const notifyUserFailureAction = createAction('[Users] Notify user failure', props<{ error: any }>());

export const deleteUserAction = createAction('[Users] Delete user', props<{ userId: number }>());
export const deleteUserSuccessAction = createAction('[Users] Delete user success', props<{ result: UserItem }>());
export const deleteUserFailureAction = createAction('[Users] Delete user failure', props<{ error: any }>());

export const unblockUserAction = createAction('[Users] Unblock user', props<{ userId: number }>());
export const unblockUserSuccessAction = createAction('[Users] Unblock user success', props<{ result: UserItem }>());
export const unblockUserFailureAction = createAction('[Users] Unblock user failure', props<{ error: any }>());
