import { authLogout, authResetControledUser } from './../../../../core/auth/auth.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { InvoicesState } from "./invoices.models";
import { loadInvoiceDetailsAction, loadInvoiceDetailsErrorAction, loadInvoiceDetailsSuccessAction, loadInvoicesListAction, loadInvoicesListErrorAction, loadInvoicesListSuccessAction, resetInvoiceDetailsAction } from "./invoices.actions";
export const initialState: InvoicesState = {
  list: {
    data: null,
    loading: true
  },
  detail: {
    data: null,
    loading: false
  }
};

const reducer = createReducer(
  initialState,
  on(loadInvoicesListAction, (state, action) => {
    console.log('Action fired, ', action, state);
    return {
      ...state,
      ...{
        list: {
          loading: true,
          error: null,
          data: initialState.list.data
        }
      }
    };
  }),

  on(loadInvoicesListSuccessAction, (state, action) => ({
    ...state,
    ...{
      list: {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),
  on(loadInvoicesListErrorAction, (state, action) => ({
    ...state,
    ...{
      list: {
        loading: false,
        error: action.error,
        data: initialState.list.data
      }
    }
  })),

  on(loadInvoiceDetailsAction, (state, action) => {
    return {
      ...state,
      ...{
        detail: {
          loading: true,
          error: null,
          data: initialState.detail.data
        }
      }
    };
  }),

  on(loadInvoiceDetailsSuccessAction, (state, action) => {
    return {
      ...state,
      ...{
        detail: {
          loading: false,
          error: null,
          data: action.result
        }
      }
    };
  }),


  on(loadInvoiceDetailsErrorAction, (state, action) => {
    return {
      ...state,
      ...{
        detail: {
          loading: false,
          error: action.error,
          data: state.detail.data
        }
      }
    };
  }),


  on(resetInvoiceDetailsAction, (state, action) => {
    return {
      ...state,
      ...{
        detail: initialState.detail
      }
    };
  }),
  on(authLogout,authResetControledUser, (state, action) => initialState),

);

export function invoicesReducer(
  state: InvoicesState | undefined,
  action: Action
): InvoicesState {
  return reducer(state, action);
}
