import {createAction, props} from "@ngrx/store";
import {DocumentOrigins} from "./documents.service";
import {AcHTTPResponse} from "../../core/http/http-response.models";
import {DocumentDetails} from "./documents.models";

export const loadDocumentDetailsAction = createAction(
    '[Documents] Load document details',
    props<{ id_file: number, origin: DocumentOrigins; }>()
);

export const loadDocumentDetailsSuccessAction = createAction(
    '[Documents] Load document details success',
    props<{ result: AcHTTPResponse<DocumentDetails>; }>()
);

export const loadDocumentDetailsFailureAction = createAction(
    '[Documents] Load document details failure',
    props<{ err: any; }>()
);