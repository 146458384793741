<div class="confirm-dialog">
    <div mat-dialog-content>
        <p cdkFocusInitial [innerHTML]="data.message | translate"></p>
    </div>
    <div *ngIf="data.image" class="img-container">
        <img alt="" [src]="data.image">
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="onButtonClick()">{{ data.buttonText | translate }}</button>
    </div>
</div>
