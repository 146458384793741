import { authLogout, authResetControledUser } from './../../../../core/auth/auth.actions';
import { createReducer, on, Action } from '@ngrx/store';
import {
  loadOperationsListAction,
  loadOperationsListSuccessAction,
  loadOperationsListFailureAction,
  operationFavoriteAction,
  operationFavoriteSuccessAction,
  operationFavoriteFailureAction,
  resetOperationsList,
  resetOperationDocuments,
  loadOperationDocumentsFailureAction,
  loadOperationDocumentsSuccessAction,
  loadOperationDocumentsAction,
  resetOperationDetails,
  loadOperationCeeSuccessAction,
  loadOperationCeeFailureAction,
  loadOperationCeeAction,
  loadOperationDetailsFailureAction,
  loadOperationDetailsSuccessAction,
  loadOperationDetailsAction,
  resetCeeDetails,
  loadCeeDocumentsAction,
  loadCeeDocumentsSuccessAction,
  loadCeeDocumentsFailureAction,
  setCeeDetails,
  loadOperationDocumentAction,
  loadOperationDocumentSuccessAction,
  loadOperationDocumentFailureAction,
  loadCeeDetailsSuccessAction,
  loadCeeDetailsFailureAction,
  loadCeeDetailsAction,
  resetOperationsDetails
} from './operations.actions';
import { OperationsState } from './operations.models';

export const initialState: OperationsState = {
  list: {
    query: null,
    data: null,
    loading: false,
    actionLoading: false,
  },
  details: {
    ceeView: false,
    operationDocumentsQuery: null,
    ceeQuery: null,
    operation: null,
    documents: null,
    cee: null,
    loading: false,
    actionLoading: false,
    error: null
  },
  cee: {
    cee: null,
    ceeDocumentsQuery: null,
    documents: null,
    loading: false,
    error: null
  },
  documentDetail: {
    document: null,
    loading: false,
    error: null
  }
}

const reducer = createReducer(
  initialState,
  on(loadOperationsListAction, (state, action) => ({
    ...state,
    ...{
      list: {
        query: action,
        loading: true,
        actionLoading: false,
        error: null,
        data: initialState.list.data
      }
    }
  })),

  on(loadOperationsListSuccessAction, (state, action) => ({
    ...state,
    ...{
      list: {
        query: state.list.query,
        loading: false,
        actionLoading: false,
        error: null,
        data: action.result
      }
    }
  })),
  on(loadOperationsListFailureAction, (state, action) => ({
    ...state,
    ...{
      list: {
        query: state.list.query,
        loading: false,
        actionLoading: false,
        error: action.error,
        data: initialState.list.data
      }
    }
  })),

  on(resetOperationsList, (state, action) => ({
    ...state,
    ...{
      list: {
        ...initialState.list
      }
    }
  })),

    on(resetOperationsDetails, (state, action) => ({
      ...state,
      ...{
        details: {
          ...state.details,
          documents: initialState.details.documents,
          ceeView: initialState.details.ceeView
        }
      }
    })),

  // favorite actions
  on(operationFavoriteAction, (state, action) => ({
    ...state,
    ...{
      list: {
        ...state.list,
        actionLoading: true
      }
    }
  })),
  on(operationFavoriteSuccessAction, (state, action) => ({
    ...state,
    ...{
      list: {
        ...state.list,
        actionLoading: false,
        data: {
          ...state.list.data,
          data: [
            ...state.list.data.data.map(o => o.id !== action.operationId ? {...o} : {...o, favorite: action.isFavorite} )
          ]
        }
      }
    }
  })),

  on(operationFavoriteFailureAction, (state, action) => ({
    ...state,
    ...{
      list: {
        ...state.list,
        actionLoading: false,
      }
    }
  })),



  // operation details reducers
  on(loadOperationDetailsAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        operation: initialState.details.operation,
        loading: true,
        error: null,
      }
    }
  })),

  on(loadOperationDetailsSuccessAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        operation: action.result,
        // loading: false,
        error: null,
      }
    }
  })),
  on(loadOperationDetailsFailureAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        operation: initialState.details.operation,
        loading: false,
        error: action.error,
      }
    }
  })),


  // operation documents
  on(loadOperationDocumentsAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        documents: initialState.details.documents,
        loading: true,
        error: null,
        ceeView: false,
        operationDocumentsQuery: action
      }
    }
  })),

  on(loadOperationDocumentsSuccessAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        documents: action.result,
        loading: false,
        error: null,
      }
    }
  })),
  on(loadOperationDocumentsFailureAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        operationDocumentsQuery: initialState.details.operationDocumentsQuery,
        documents: initialState.details.documents,
        loading: false,
        error: action.error,
      }
    }
  })),

  // operation document
  on(loadOperationDocumentAction, (state, action) => ({
    ...state,
    ...{
      documentDetail: {
        document: initialState.documentDetail.document,
        loading: true,
        error: null,
      }
    }
  })),
  on(loadOperationDocumentSuccessAction, (state, action) => ({
    ...state,
    ...{
      documentDetail: {
        document: action.result,
        loading: false,
        error: null,
      }
    }
  })),

  on(loadOperationDocumentFailureAction, (state, action) => ({
    ...state,
    ...{
      documentDetail: {
        document: state.documentDetail.document,
        loading: false,
        error: action.error,
      }
    }
  })),

  // operation cee
  on(loadOperationCeeAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        cee: initialState.details.cee,
        loading: true,
        error: null,
        ceeView: true,
        ceeQuery: action
      }
    }
  })),

  on(loadOperationCeeSuccessAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        cee: action.result,
        loading: false,
        error: null,
      }
    }
  })),
  on(loadOperationCeeFailureAction, (state, action) => ({
    ...state,
    ...{
      details: {
        ...state.details,
        cee: initialState.details.cee,
        loading: false,
        error: action.error,
        ceeQuery: initialState.details.ceeQuery,
      }
    }
  })),

  on(resetOperationDetails, (state, action) => ({
    ...state,
    ...{
      details: {
        ...initialState.details
      }
    }
  })),

  // cee details

  on(setCeeDetails, (state, action) => ({
    ...state,
    ...{
      cee: {
        ...state.cee,
        cee: action.cee
      }
    }
  })),

    on(loadCeeDetailsAction, (state, action) => ({
      ...state,
      ...{
        cee: {
          ...state.cee,
          loading: true,
          error: null,
        }
      }
    })),

    on(loadCeeDetailsSuccessAction, (state, action) => ({
      ...state,
      ...{
        cee: {
          ...state.cee,
          cee: action.result
        }
      }
    })),

    on(loadCeeDetailsFailureAction, (state, action) => ({
      ...state,
      ...{
        cee: {
          ...state.cee,
          loading: false,
          error: action.error,
        }
      }
    })),

  // operation documents
  on(loadCeeDocumentsAction, (state, action) => ({
    ...state,
    ...{
      cee: {
        ...state.cee,
        ceeDocumentsQuery: action,
        documents: initialState.cee.documents,
        loading: true,
        error: null,
      }
    }
  })),

  on(loadCeeDocumentsSuccessAction, (state, action) => ({
    ...state,
    ...{
      cee: {
        ...state.cee,
        documents: action.result,
        loading: false,
        error: null,
      }
    }
  })),
  on(loadCeeDocumentsFailureAction, (state, action) => ({
    ...state,
    ...{
      cee: {
        ...state.cee,
        documents: initialState.cee.documents,
        loading: false,
        error: action.error,
      }
    }
  })),

  on(resetCeeDetails, (state, action) => ({
    ...state,
    ...{
      cee: {
        ...initialState.cee
      }
    }
  })),

  on(authLogout,authResetControledUser, (state, action) => initialState),

);

export function operationsReducer(
  state: OperationsState | undefined,
  action: Action
): OperationsState {
  return reducer(state, action);
}
