import { NotificationItem } from './notifications.models';
import { AcPaginatedHTTPResponse } from './../../../../core/http/http-response.models';
import { createAction, props } from '@ngrx/store';

export const loadNotificationsAction = createAction('[Notifications] Load Notifications', props<{ vu?: boolean, page?: number}>());
export const loadNotificationsSuccessAction = createAction('[Notifications] Load Notifications success', props<{ result: AcPaginatedHTTPResponse<NotificationItem> }>());
export const loadNotificationsErrorAction = createAction('[Notifications] Load Notifications error', props<{ error: any }>());


export const loadAppBarNotificationsAction = createAction('[Notifications] Load AppBar Notifications');
export const loadAppBarNotificationsSuccessAction = createAction('[Notifications] Load AppBar Notifications success', props<{ result: AcPaginatedHTTPResponse<NotificationItem> }>());
export const loadAppBarNotificationsErrorAction = createAction('[Notifications] Load AppBar Notifications error', props<{ error: any }>());


export const setNotificationSeenAction = createAction('[Notifications] Set seen', props<{ item?: NotificationItem, page?: number}>());
export const setNotificationSeenSuccessAction = createAction('[Notifications] Set seen success');
export const setNotificationSeenErrorAction = createAction('[Notifications] Set seen error', props<{ error: any }>());
