import {Component, OnInit, OnDestroy, ViewChild, Inject, ChangeDetectorRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Observable, Subject, Subscription} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-model-picker',
    templateUrl: 'model-picker.component.html',
    styleUrls: ['./model-picker.component.scss']
})
export class ModelPickerComponent<T extends { id: any }> implements OnInit, OnDestroy {
    @ViewChild('acTable', {static: true}) acTable;
    items: T[];
    selectedIds: any[];
    loadItems?: (page?: number, value?: string) => Observable<T[]>;
    loading$: Observable<boolean>;
    items$: Observable<any>;
    columns = [];
    title: string;
    svgIcon: string;
    destroy$: Subject<boolean> = new Subject();
    paginatorData = null;
    noPaginator: boolean = false;
    hideSearch: boolean = false;
    private subscriptions: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<ModelPickerComponent<T>>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cdr: ChangeDetectorRef,
    ) {
        dialogRef.disableClose = true;
        this.selectedIds = Array.from(this.data.selectedIds);
        this.loadItems = this.data.loadItems;
        this.loading$ = this.data.loading$;
        this.items$ = this.data.items$;
        this.columns = this.data.columns;
        this.title = this.data.title;
        this.svgIcon = this.data.svgIcon;
        this.noPaginator = this.data.noPaginator ?? false;
        this.hideSearch = this.data.hideSearch ?? false;
    }

    ngOnInit(): void {
        this.subscriptions.push(this.items$.pipe(
            filter(elem => elem != null),
            takeUntil(this.destroy$)
        ).subscribe(response => {
            this.items = response.data;
            const selectedItems = this.items.filter((item: T) => {
                return this.selectedIds.includes(item.id);
            });
            this.acTable.selection.select(...selectedItems);
            if (!this.noPaginator) {
                this.paginatorData = {
                    length: response.total,
                    pageSize: response.per_page,
                    pageIndex: response.current_page - 1
                };
            }
            this.cdr.detectChanges();
        }));

        this.acTable.selection.changed.subscribe(selectionChange => {
            const addedIds = selectionChange.added.map((item: T) => item.id);
            const toAddIds = addedIds.filter((id: any) => !this.selectedIds.includes(id));
            toAddIds.forEach((id: any) => {
                this.selectedIds.push(id);
            });
            const removedIds = selectionChange.removed.map((item: T) => item.id);
            this.selectedIds = this.selectedIds.filter((id: any) => !removedIds.includes(id));
        });
        if (this.loadItems) {
            this.loadItems();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onSearch(value: string) {
        this.loadItems(1, value);
    }

    onPageChanged(page: number) {
        this.loadItems(page);
    }

    validate() {
        this.dialogRef.close(this.selectedIds);
    }

}
