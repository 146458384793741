<div mat-dialog-title>
    <ac-primary-title
        [title]="title"
        [svgIcon]="svgIcon"
    ></ac-primary-title>
</div>
<mat-dialog-content>
    <ac-search-field
            *ngIf="!hideSearch"
        (onSearch)="onSearch($event)"
    ></ac-search-field>
    <ac-table #acTable
        [entries]="items"
        [paginatorData]="paginatorData"
        (onPageChange)="onPageChanged($event)"
        [emptyMessage]="'ac.global.result.empty' | translate"
        [loading]="loading$ | async"
        [columns]="columns"
        [withSelection]="true"
    >
    </ac-table>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="!(loading$ | async)">
    <div *ngIf="items && items.length > 0; else okButton">
        <button
            mat-raised-button
            color="default"
            mat-dialog-close
        >
            <mat-icon matPrefix>close</mat-icon>
            {{ 'ac.global.buttons.cancel' | translate }}
        </button>
        <button
            mat-raised-button
            color="primary"
            (click)="validate()"
            cdkFocusInitial
        >
            <mat-icon matPrefix>check</mat-icon>
            {{ 'ac.global.buttons.validate' | translate }}
        </button>
    </div>
    <ng-template #okButton>
        <button
            mat-raised-button
            color="primary"
            mat-dialog-close
        >
            <mat-icon matPrefix>check</mat-icon>
            {{ 'ac.global.buttons.ok' | translate }}
        </button>
    </ng-template>
</mat-dialog-actions>
