<div class="confirm-dialog">
    <h1 *ngIf="!!data.title && data.title.length > 0" mat-dialog-title>{{data.title | translate}}</h1>
    <div mat-dialog-content>
        <p cdkFocusInitial [innerHTML]="data.message | translate"></p>
    </div>
    <div mat-dialog-actions align="center">
        <button
            mat-raised-button
            (click)="onNoClick()"
        >
            <mat-icon matPrefix>close</mat-icon>
            {{'ac.global.dialog.cancel' | translate}}
        </button>
        <button
            mat-raised-button
            color="primary"
            (click)="onYesClick()"
        >
            <mat-icon matPrefix>check</mat-icon>
            {{'ac.global.dialog.confirm' | translate}}
        </button>
    </div>
</div>
