<ng-container>
    <div #trigger="matMenuTrigger"
                  (mouseover)="trigger.openMenu()" [matMenuTriggerFor]="popOver">
        <ng-content select="[subject]"></ng-content>
    </div>

    <mat-menu #popOver="matMenu" >
          <span class="popover-menu-item" mat-menu-item (mouseleave)="trigger.closeMenu()" [disableRipple]="true" (click)="$event.stopPropagation()">
              <ng-content select="[popoverContent]"></ng-content>
          </span>
    </mat-menu>
</ng-container>
