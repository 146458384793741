import { AcPaginatedHTTPResponse, ListActionProps, AcHTTPResponse } from './../../../../core/http/http-response.models';
import { createAction, props } from '@ngrx/store';
import { ContractItem, ContractSort, ContractSortOrder, ContractStatus } from './contracts.models';

export const loadContractsListAction = createAction(
  '[Contracts] Load list',
  props<ListActionProps<ContractSort, ContractStatus>>());
export const loadContractsListSucessAction = createAction('[Contracts] Load list success', props<{ result: AcPaginatedHTTPResponse<ContractItem> }>());
export const loadContractsListFailureAction = createAction('[Contracts] Load list failure', props<{ error: any }>());

export const resetContractsList = createAction(
  '[Contracts] reset list',
);

export const loadContractsDetailsAction = createAction('[Contracts] Load Details',props<{id: string}>());
export const loadContractsDetailsSuccessAction = createAction('[Contracts] Load Details Success',props<{result: AcHTTPResponse<ContractItem>}>());
export const loadContractsDetailsFailureAction = createAction('[Contracts] Load Details Failure',props<{error: any}>());
export const resetContractsDetailsAction = createAction('[Contracts] Reset Details');

