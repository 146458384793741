import { Pipe, PipeTransform } from '@angular/core';
import {parseISO} from "date-fns";

@Pipe({
  name: 'acDate'
})
export class AcDatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string|Date {
    if (!!value) {
      return parseISO(value);
    }

    return '';
  }

}
