import {BehaviorSubject, Observable} from "rxjs";
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {

  private history: string[] = [];
  currentURL = null;

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  constructor(private router: Router, private location: Location) {}

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  push(url) {
    this.history.push(url);
  }

  back(defaultRoute: string = '..') {
    if (this.history.length > 0) {
      this.history.pop();
      return this.location.back();
    }

    this.router.navigateByUrl(defaultRoute);
  }
}
