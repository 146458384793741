import {DocumentsState} from "./documents.models";
import {Action, createReducer, on} from "@ngrx/store";
import {
    loadDocumentDetailsAction,
    loadDocumentDetailsFailureAction,
    loadDocumentDetailsSuccessAction
} from "./documents.actions";

export const initialState: DocumentsState = {
    documentDetails: {
        details: null,
        loading: false,
        error: null
    }
};

const reducer = createReducer(
    initialState,
    // Détails document
    on(loadDocumentDetailsAction, (state, action) => ({
        ...state,
        documentDetails: {
            details: initialState.documentDetails.details,
            loading: true,
            err: false
        }
    })),
    on(loadDocumentDetailsSuccessAction, (state, action) => ({
        ...state,
        documentDetails: {
            details: action.result.data,
            loading: false,
            err: false
        }
    })),
    on(loadDocumentDetailsFailureAction, (state, action) => ({
        ...state,
        documentDetails: {
            details: initialState.documentDetails.details,
            loading: false,
            err: action.err
        }
    })),
);

export function documentsReducer(
    state: DocumentsState | undefined,
    action: Action
): DocumentsState {
    return reducer(state, action)
}