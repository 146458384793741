import { createReducer, on, Action } from '@ngrx/store';
import {
  tiersListAction,
    tiersListSucessAction,
    tiersListFailureAction,
} from './tiers.actions';
import { TiersState } from './tiers.models';

export const initialState: TiersState = {
  list: {
    data: null,
    loading: false
  },
}

const reducer = createReducer(
  initialState,
  on(tiersListAction, (state, action) => {
      return ({
          ...state,
          ...{
              list: {
                  loading: true,
                  error: null,
                  data: initialState.list.data
              }
          }
      })
  }),

  on(tiersListSucessAction, (state, action) => ({
    ...state,
    ...{
      list: {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),
  on(tiersListFailureAction, (state, action) => ({
    ...state,
    ...{
      list: {
        loading: false,
        error: action.error,
        data: initialState.list.data
      }
    }
  })),

);

export function tiersReducer(
  state: TiersState | undefined,
  action: Action
): TiersState {
  return reducer(state, action);
}
