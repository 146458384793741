import {CodeMarches} from "../../features/home/operations/core/operations.models";
import {DocumentOrigins} from "./documents.service";
import {PDFSource} from "ng2-pdf-viewer";


export enum BaseDocumentStatuses {
  CONFORM = 'conforme',
  NON_CONFORM = 'non_conforme'
}
export interface BaseDocument<TStatus> {
  id:  number;
  libelle:  string;
  status:  TStatus;
  id_file:  number|string;
  created_at?:  string;
  updated_at?:  string;
}

export interface Fichier {
  nom?: string;
  intitule?: string;
  numero?: number;
}

export interface Dossier {
  nom?: string;
  intitule?: string;
  fichiers: Array<Fichier>
}

export interface DocumentDetails {
  id_file: number;
  intitule: string;
  fichiers: Array<Fichier>;
  dossiers: Array<Dossier>
}

export interface DocumentsState {
  documentDetails: {
    details?: DocumentDetails;
    loading: boolean;
    error?: any
  }
}

export interface PDFInfo {
  pdfSource: PDFSource,
  intitule?: string
}

export type BaseDocumentSort = 'libelle' | 'updated_at' | 'status';

