import { Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, ControlContainer, FormControl, FormControlDirective } from '@angular/forms';

@Component({
  template: '',
})
export class FormControlComponent implements ControlValueAccessor {
  @ViewChild(FormControlDirective, {static: true}) formControlDirective: FormControlDirective;
  @Input() formControl: FormControl;
  @Input() formControlName: string;

  constructor(
    private controlContainer: ControlContainer
  ) { }

  get control(): FormControl {
    return this.formControl || this.controlContainer.control?.get(this.formControlName) as FormControl;
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor?.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor?.writeValue(obj);
  }

  setDisabledState(disabled: boolean): void {
    this.formControlDirective.valueAccessor?.setDisabledState?.(disabled);
  }

}
