import {SearchState} from "./search.models";
import {Action, createReducer, on} from "@ngrx/store";
import {searchAction, searchErrorAction, searchSuccessAction} from "./search.actions";


const initialState: SearchState = {
  loading: false,
  error: null,
  data: null,
};


const reducer = createReducer(initialState,

  on(searchAction, (state, action) => ({
    ...state,
    loading: true,
    error: null,
    data: initialState.data
  })),

  on(searchSuccessAction, (state, action) => ({
    ...state,
    loading: false,
    data: action.result,
    error: null
  })),

  on(searchErrorAction, (state, action) => ({
    ...state,
    loading: false,
    data: initialState.data,
    error: action.error
  }))
);


export function SearchReducer(
  state: SearchState | undefined,
  action: Action
): SearchState {
  return reducer(state, action);
}
