<mat-dialog-content>
    <ac-primary-title
            [title]="'ac.home.popupVersion.modal.title' | translate"
            [svgIcon]="'new-box'"
    ></ac-primary-title>
    <h3>Pour les utilisateurs administrateurs :</h3>
    <mat-list>
        <mat-list-item>- L’accès à vos contrats et avenants est désormais disponible dans le menu : Mes contrats (lecture et téléchargement)</mat-list-item>
        <mat-list-item>- L’ajout d’utilisateurs supplémentaires pour les comptes administrateurs vous permet désormais de filtrer la vision par métiers et missions.</mat-list-item>
    </mat-list>

    <h3>Pour tous les utilisateurs :</h3>
    <mat-list>
        <mat-list-item>- L’export des observations pour les missions Diagnostics Immobiliers et Environnement est possible dans l’onglet Mon patrimoine.</mat-list-item>
    </mat-list>

    <h4>Vous avez une suggestion d’amélioration ? N’hésitez pas à nous écrire via l’onglet NOUS CONTACTER.</h4>

</mat-dialog-content>

<ac-loading-overlay *ngIf="(loading$ | async)"></ac-loading-overlay>
<mat-dialog-actions>
    <div class="buttons-group">
        <!-- validate -->
        <button
            mat-raised-button
            color="primary"
            (click)="validate()"
        >
            <mat-icon *ngIf="(loading$ | async)" matPrefix><mat-spinner diameter="20"></mat-spinner></mat-icon>
            <mat-icon *ngIf="!(loading$ | async)" matPrefix>check</mat-icon>
            {{ 'ac.home.popupVersion.modal.checkbox.label' | translate }}
        </button>
    </div>

</mat-dialog-actions>
