import { authLogout, authResetControledUser } from './../../../../core/auth/auth.actions';
import { createReducer, on, Action } from '@ngrx/store';
import {
  loadContractsListAction,
  loadContractsListSucessAction,
  loadContractsListFailureAction,
  resetContractsList,
  loadContractsDetailsAction,
  loadContractsDetailsSuccessAction,
  loadContractsDetailsFailureAction,
  resetContractsDetailsAction
} from './contracts.actions';
import { ContractsState } from './contracts.models';
export const initialState: ContractsState = {
  list: {
    query: null,
    data: null,
    loading: false
  },
  details: {
    data: null,
    loading: false
  }
}

const reducer = createReducer(
  initialState,
  on(loadContractsListAction, (state, action) => ({
    ...state,
    ...{
      list: {
        query: action,
        loading: true,
        error: null,
        data: initialState.list.data
      }
    }
  })),

  on(loadContractsListSucessAction, (state, action) => ({
    ...state,
    ...{
      list: {
        query: state.list.query,
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),
  on(loadContractsListFailureAction, (state, action) => ({
    ...state,
    ...{
      list: {
        query: state.list.query,
        loading: false,
        error: action.error,
        data: initialState.list.data
      }
    }
  })),
  on(resetContractsList, (state, action) => ({
    ...state,
    ...{
      list: {
        ...initialState.list
      }
    }
  })),

  on(loadContractsDetailsAction, (state, action) => ({
    ...state,
    ...{
      details: {
        loading: true,
        error: null,
        data: initialState.details.data
      }
    }
  })),

  on(loadContractsDetailsSuccessAction, (state, action) => ({
    ...state,
    ...{
      details: {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),

  on(loadContractsDetailsFailureAction, (state, action) => ({
    ...state,
    ...{
      details: {
        loading: false,
        error: action.error,
        data: state.details.data
      }
    }
  })),



  on(resetContractsDetailsAction, (state, action) => ({
    ...state,
    ...{
      details: initialState.details
    }
  })),

  on(authLogout,authResetControledUser, (state, action) => initialState),



);

export function contractsReducer(
  state: ContractsState | undefined,
  action: Action
): ContractsState {
  return reducer(state, action);
}
