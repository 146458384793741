import {switchMap, map, tap, share} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import { api } from "../../core/api/api.vars";
import { of, Observable } from 'rxjs';
import {AcHTTPResponse, AcPaginatedHTTPResponse} from "../../core/http/http-response.models";
import {BaseDocument, BaseDocumentStatuses, DocumentDetails} from "./documents.models";
import {environment} from "../../../environments/environment";
import {UserDocument} from "../../features/home/dashboard/core/dashboard.models";


export enum DocumentOrigins {
  CONTRATS = 'contrats',
  AVENANTS = 'avenants',
  FACTURES = 'factures',
  OPERATIONS = 'documents_operations',
  CEE = 'documents_cee',
  CERTIFICATION = 'documents_certification',
  INTERVENTIONS = 'documents_interventions'
}

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private httpClient: HttpClient) { }

  downloadAll(origin: DocumentOrigins, params: {[key: string]: string} = {}): Observable<any> {
    return this.httpClient.get(api.endpoints.documents.DOWNLOAD_ALL.replace('{{origin}}', origin), {
      params
    });
  }

  downloadByUrl(downloadLink: string, type: string = null, fileName: string): Observable<string> {
    return this.httpClient.get(downloadLink, { responseType: 'blob', observe: 'response' })
      .pipe(
        tap((data: HttpResponse<Blob>) => {
          // Extract the file name from header
          // Ex: Content-Disposition: attachment; filename=documents_operations_18_640efc81297765.72945287.zip
          if (data.headers.has('Content-Disposition')) {
              const contentDisposition = data.headers.get('Content-Disposition');
              const contentDispositionFileName = contentDisposition
                  .split(';')[1]
                  .split('filename')[1]
                  .split('=')[1]
                  .trim()
                  .match(/([^"]+)/)[1];
              if (contentDispositionFileName) {
                  fileName = contentDispositionFileName;
              }
          }
          this.downloadFile(data.body, type, fileName)
        }),
        map(() => downloadLink),
        );
  }

  downloadSelected(idFiles: (number | string)[], origin: DocumentOrigins): Observable<string> {
    const type = idFiles.length > 1 ? 'application/zip' : 'application/pdf';
    const name = `${idFiles.join('-')}.${idFiles.length > 1 ? 'zip' : 'pdf'}`;
    const downloadLink = api.endpoints.documents.DOWNLOAD_SELECTED
      .replace('{{id_files}}', idFiles.join(';'))
      .replace('{{origin}}', origin);

    return this.downloadByUrl(downloadLink, type, name);
  }

  /**
   * Requête pour demander le téléchargement des fichiers avec envoi d'un lien par email
   * @param idFiles
   * @param origin
   */
  downloadSelectedByEmail(idFiles: (number | string)[], origin: DocumentOrigins): Observable<any> {
    return this.httpClient.get(api.endpoints.documents.DOWNLOAD_SELECTED
        .replace('{{id_files}}', idFiles.join(';'))
        .replace('{{origin}}', origin));
  }

  private downloadFile(data: any, type: string = null, name: string) {
    let params = null;
    if (type !== null) {
      params = {type};
    }
    const blob = new Blob([data], params);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  userDocuments(count = 5): Observable<AcPaginatedHTTPResponse<UserDocument>> {
    let _params = new HttpParams();
    _params = _params.append('per_page', count);
    _params = _params.append('sort_by', 'created_at');
    _params = _params.append('sort_order', 'desc');

    return this.httpClient
      .get<AcPaginatedHTTPResponse<UserDocument>>(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.documents.USER_DOCUMENTS}`,
        {
          params: _params
        }
      )
      .pipe(share());
  }

  documentDetails(document: number, origin: DocumentOrigins) {
    let _params = new HttpParams();
    _params = _params.append('origin', origin);

    return this.httpClient
        .get<AcHTTPResponse<DocumentDetails>>(
            api.endpoints.documents.DOCUMENT_DETAILS.replace("{{id_file}}", `${document}`),
            {
              params: _params
            }
        ).pipe(share())
  }
}
