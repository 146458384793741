import { authLogout, authResetControledUser } from './../../../../core/auth/auth.actions';
import {MyUsersState} from "./my-users.models";
import {Action, createReducer, on} from "@ngrx/store";
import {
    addMyUserAction, addMyUserFailureAction, addMyUserSuccessAction,
    getMyUserAction, getMyUserFailureAction, getMyUserSuccessAction,
    deleteMyUserAction, deleteMyUserFailureAction, deleteMyUserSuccessAction,
    updateMyUserAction, updateMyUserFailureAction, updateMyUserSuccessAction,
    loadMyUsersListAction,
    loadMyUsersListFailureAction,
    loadMyUsersListSuccessAction
} from "./my-users.actions";

export const initialState: MyUsersState = {
    list: {
        data: null,
        loading: false
    },
    get: {
        data: null,
        loading: false
    },
    delete: {
        loading: false
    },
    create: {
        loading: false
    },
    update: {
        loading: false
    }
};

const reducer = createReducer(
    initialState,
    on(loadMyUsersListAction, (state, action) => ({
        ...state,
        ...{
            list: {
                loading: true,
                error: null,
                data: initialState.list.data
            }
        }
    })),
    on(loadMyUsersListSuccessAction, (state, action) => ({
        ...state,
        ...{
            list: {
                loading: false,
                error: null,
                data: action.result
            }
        }
    })),
    on(loadMyUsersListFailureAction, (state, action) => ({
        ...state,
        ...{
            list: {
                loading: false,
                error: action.error,
                data: initialState.list.data
            }
        }
    })),

    on(getMyUserAction, (state, action) => ({
        ...state,
        ...{
            get: {
                loading: true,
                error: null,
                data: initialState.get.data,
            }
        }
    })),
    on(getMyUserSuccessAction, (state, action) => ({
        ...state,
        ...{
            get: {
                loading: false,
                error: null,
                data: action.result,
            }
        }
    })),
    on(getMyUserFailureAction, (state, action) => ({
        ...state,
        ...{
            get: {
                loading: false,
                error: action.error,
                data: initialState.get.data,
            }
        }
    })),

    on(deleteMyUserAction, (state, action) => ({
        ...state,
        ...{
            delete: {
                loading: true,
                error: null,
            }
        }
    })),
    on(deleteMyUserSuccessAction, (state, action) => ({
        ...state,
        ...{
            delete: {
                loading: false,
                error: null,
            }
        }
    })),
    on(deleteMyUserFailureAction, (state, action) => ({
        ...state,
        ...{
            delete: {
                loading: false,
                error: action.error,
            }
        }
    })),

    on(addMyUserAction, (state, action) => ({
        ...state,
        ...{
            create: {
                loading: true,
                error: null,
            }
        }
    })),
    on(addMyUserSuccessAction, (state, action) => ({
        ...state,
        ...{
            create: {
                loading: false,
                error: null,
            }
        }
    })),
    on(addMyUserFailureAction, (state, action) => ({
        ...state,
        ...{
            create: {
                loading: false,
                error: action.error,
            }
        }
    })),

    on(updateMyUserAction, (state, action) => ({
        ...state,
        ...{
            update: {
                loading: true,
                error: null,
            }
        }
    })),
    on(updateMyUserSuccessAction, (state, action) => ({
        ...state,
        ...{
            update: {
                loading: false,
                error: null,
            }
        }
    })),
    on(updateMyUserFailureAction, (state, action) => ({
        ...state,
        ...{
            update: {
                loading: false,
                error: action.error,
            }
        }
    })),

    on(authLogout,authResetControledUser, (state, action) => initialState),
    );

export function myUsersReducer(
    state: MyUsersState | undefined,
    action: Action
): MyUsersState {
    return reducer(state, action)
}
