import { AcHTTPResponse } from './../../../../core/http/http-response.models';
import { createAction, props } from "@ngrx/store";
import { InvoiceItem, InvoiceSort, InvoiceSortOrder, InvoiceStatus, InvoiceType } from "./invoices.models";
import { AcPaginatedHTTPResponse } from "../../../../core/http/http-response.models";

export const loadInvoicesListAction = createAction('[Invoices] Load list', props<{
  page: number,
  q?: string,
  sortBy?: InvoiceSort,
  sortOrder?: InvoiceSortOrder,
  status?: InvoiceStatus,
  invoiceType?: InvoiceType,
  createdAtGte?: Date,
  createdAtLte?: Date,
  metiers?: Array<string>,
  operationId?: number,
  patrimoineId?: string,
  contratId?: number,
  perPage?: number,
}>());
export const loadInvoicesListSuccessAction = createAction('[Invoices] Load list success', props<{
  result: AcPaginatedHTTPResponse<InvoiceItem>
}>());
export const loadInvoicesListErrorAction = createAction('[Invoices] Load list error', props<{
  error: any
}>());


export const loadInvoiceDetailsAction = createAction('[Invoices] Load Detail', props<{ id: string }>());
export const loadInvoiceDetailsSuccessAction = createAction('[Invoices] Load Detail success', props<{ result: AcHTTPResponse<InvoiceItem> }>());
export const loadInvoiceDetailsErrorAction = createAction('[Invoices] Load Detail error', props<{ error: any }>());
export const resetInvoiceDetailsAction = createAction('[Invoices] Reset Detail');
