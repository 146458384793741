import {Component, OnInit, OnDestroy, Input} from '@angular/core';

@Component({
  selector: 'app-cgu-content',
  templateUrl: './cgu-content.component.html',
  styleUrls: ['./cgu-content.component.scss'],
})
export class CguContentComponent implements OnInit, OnDestroy {

    @Input()
    fullSize: boolean = false;

    constructor() { }

    ngOnInit(): void {
        const element = document.querySelector('app-cgu-content')
        element.addEventListener('click', this.onClick);
    }

    ngOnDestroy(): void {
        const element = document.querySelector('app-cgu-content')
        element.removeEventListener('click', this.onClick);
    }

    onClick(event: Event): void {
        if (event.target instanceof HTMLAnchorElement && event.target.hash.length > 0) {
            event.preventDefault();
            const targetName = event.target.hash.substring(1);
            const targetElement = document.querySelector('[name="' + targetName + '"]');
            if (targetElement) {
                targetElement.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'nearest' });
            }
        }
    }

}
