import { routeAnimations } from '../core/animations/route.animations';
import { LocalStorageService } from '../core/local-storage/local-storage.service';
import { Store } from '@ngrx/store';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BackButtonService} from "../core/utils/back-button.service";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  navigation = [
    { link: '/home/users', label: 'Gestion des utilisateurs', icon: 'group',  },
    { link: '/home/contrats', label: 'Gestion des contracts', icon: 'description', },
  ];

  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private store: Store,
    private storageService: LocalStorageService,
    private translateService: TranslateService,
    private backBtnService: BackButtonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.storageService.testLocalStorage();
    this.translateService.use('fr');

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const extras = this.router.getCurrentNavigation()?.extras;
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.backBtnService.setPreviousUrl(this.previousUrl);
        if (extras.replaceUrl || extras.skipLocationChange) {
          return;
        }
        this.backBtnService.push(this.previousUrl);
      });

  }
}
