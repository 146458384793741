import {Component, Input, Output, EventEmitter, OnInit, OnChanges, ElementRef, TemplateRef, ViewChild, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteTrigger, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {Observable, Subject, merge} from 'rxjs';
import {filter, map, tap, switchMap, startWith, debounceTime, distinctUntilChanged, share} from 'rxjs/operators';

import {FormControlComponent} from "../../core/form/components/form-control.component";
import {ngValueAccessorProvider} from '../../core/form/providers/form.providers';
import {verticalCollapse} from "../../core/animations/collapse.animations";

@Component({
  selector: 'chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss'],
  animations: [verticalCollapse],
  providers: [ngValueAccessorProvider(ChipInputComponent)],
})
export class ChipInputComponent<T extends { id: number }> extends FormControlComponent implements OnInit, OnChanges {
    @ViewChild('searchInput') private searchInput: ElementRef<HTMLInputElement>;
    @ViewChild('searchInput', { read: MatAutocompleteTrigger, static: false}) private autocomplete: MatAutocompleteTrigger;
    @Input() label: string;
    @Input() placeholder: string = '';
    @Input() hint: string = '';
    @Input() items: T[];
    @Input() chipTextTemplate: TemplateRef<any>;
    @Input() optionTextTemplate: TemplateRef<any>;
    @Output('search') searchEmitter = new EventEmitter<string>();

    options = {
        debounceTime: 1000,
    };
    searchInputControl = new FormControl('');
    loading$: Observable<boolean>;
    filteredItems$: Observable<T[]>;
    private _itemsSubject = new Subject<T[]>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items && Array.isArray(changes.items.currentValue)) {
            this._itemsSubject.next(changes.items.currentValue as T[]);
        }
    }

    ngOnInit(): void {
        if (!Array.isArray(this.control.value)) {
            throw new Error('Control value type must be array');
        }

        const items$ = this._itemsSubject.asObservable();

        const searchText$ = this.searchInputControl.valueChanges.pipe(
            debounceTime(this.options.debounceTime),
            filter((value: any) => typeof value === 'string'),
            startWith(''),
            map((value: string) => value.trim().toLowerCase()),
            distinctUntilChanged(),
            tap((value: string) => this.searchEmitter.emit(value)),
        );

        this.loading$ = merge(
            items$.pipe(map(() => false)),
            searchText$.pipe(map(() => true)),
        ).pipe(
            tap((value: boolean) => {
                if (value) {
                    this.control.disable();
                    if (this.autocomplete) this.autocomplete.closePanel();
                }
                else {
                    this.control.enable();
                    if (this.autocomplete) this.autocomplete.openPanel();
                }
            }),
            share(),
        );

        this.filteredItems$ = items$.pipe(
            switchMap((items: T[]) => this.control.valueChanges.pipe(
                startWith(this.control.value as T[]),
                map((selectedItems: T[]) => this.arrayRemove(items, selectedItems)),
            )),
        );

    }

    private arrayRemove(array1: T[], array2: T[]): T[] {
        return array1.filter((array1Item: T) => !array2.find((array2Item: T) => array2Item.id === array1Item.id));
    }

    onChipRemove(item: T): void {
        let items = this.control.value;
        const index = items.indexOf(item);
        if (index >= 0) {
            items.splice(index, 1);
        }
        this.control.setValue(items);
    }

    onInputClear(): void {
        this.searchInput.nativeElement.value = '';
        this.searchInputControl.setValue('');
    }

    onAutocompleteSelect(event: MatAutocompleteSelectedEvent): void {
        const item: T = event.option.value;
        let items = this.control.value || [];
        items.push(item);
        this.control.setValue(items);
    }

}
