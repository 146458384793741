<mat-form-field>

    <mat-label *ngIf="label">{{ label }}</mat-label>

    <!-- chips -->
    <mat-chip-grid
        #chipGrid
        [formControl]="control"
    >
        <mat-chip-row
            [@verticalCollapse]=""
            *ngFor="let item of control.value"
            (removed)="onChipRemove(item)"
        >
            <ng-template #chipTextDefaultTemplate let-item="item">{{ item }}</ng-template>
            <ng-container
                [ngTemplateOutlet]="chipTextTemplate || chipTextDefaultTemplate"
                [ngTemplateOutletContext]="{ item: item }"
            ></ng-container>
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
        <input
            #searchInput
            type="text"
            [formControl]="searchInputControl"
            [placeholder]="placeholder"
            [matAutocomplete]="autocomplete"
            [matChipInputFor]="chipGrid"
        >
        <mat-icon *ngIf="loading$ | async" matSuffix><mat-spinner diameter="20"></mat-spinner></mat-icon>
        <button
            matSuffix
            mat-icon-button
            *ngIf="!(loading$ | async) && searchInputControl.value != ''"
            (click)="onInputClear()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </mat-chip-grid>

    <!-- autocomplete -->
    <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="onAutocompleteSelect($event)">
        <mat-option *ngFor="let item of filteredItems$ | async" [value]="item">
            <ng-template #optionTextDefaultTemplate let-item="item">{{ item }}</ng-template>
            <ng-container
                [ngTemplateOutlet]="optionTextTemplate || optionTextDefaultTemplate"
                [ngTemplateOutletContext]="{ item: item }"
            ></ng-container>
        </mat-option>
    </mat-autocomplete>

    <!-- hint -->
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>

    <!-- errors -->
    <mat-error *ngIf="control.hasError('required')">
        {{ 'ac.global.form.required' | translate }}
    </mat-error>

</mat-form-field>
