import { AcHTTPResponse } from './../http/http-response.models';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  LoginRequest,
  AuthToken,
  RefreshTokenRequest, AuthResponse, UserInfoResponse,
} from './auth.models';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { share } from 'rxjs/operators';
import {api} from "../api/api.vars";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private httpClient: HttpClient) { }

  retrieveAuthToken(
    request: LoginRequest,
  ): Observable<AcHTTPResponse<AuthResponse>> {

    return this.httpClient.post<AcHTTPResponse<AuthResponse>>(
      `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.auth.LOGIN}`,
      {
        ...request
      },
    );
  }

  retrieveRefreshToken(tokenInfos: RefreshTokenRequest): Observable<AcHTTPResponse<AuthToken>> {

    return this.httpClient
      .post<AcHTTPResponse<AuthToken>>(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.auth.REFRESH_TOKEN}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokenInfos.token}`,
          }
        }
      )
      .pipe(share());
  }
  retrieveUserInfo(): Observable<AcHTTPResponse<UserInfoResponse>> {

    return this.httpClient
      .get<AcHTTPResponse<UserInfoResponse>>(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.auth.USER_INFO}`,
        {},
      )
      .pipe(share());
  }

  logout(): Observable<any> {
    return of(null);
  }
}
