import {
  PatrimonySort,
  PatrimonySortOrder,
  PatrimonyStatus,
  PatrimonyItem,
  PatrimonyDocumentSort,
  PatrimonyDocumentStatuses,
  PatrimonyDocument,
  PatrimonyDocumentMetadata,
  PatrimonyCertificatItem,
  PatrimonyCertificatMetadata,
  PatrimonyCertificatSort,
  PatrimonyCertificatStatuses,
} from './patrimonies.models';
import { AcPaginatedHTTPResponse, ListActionProps, AcHTTPResponse } from './../../../../core/http/http-response.models';
import { createAction, props } from '@ngrx/store';

export const loadPatrimoniesListAction = createAction('[Patrimonies] Load list',
  props<ListActionProps<PatrimonySort, PatrimonyStatus>>()
);
export const loadPatrimoniesListSuccessAction = createAction('[Patrimonies] Load list success', props<{ result: AcPaginatedHTTPResponse<PatrimonyItem> }>());
export const loadPatrimoniesListFailureAction = createAction('[Patrimonies] Load list failure', props<{ error: any }>());
export const resetPatrimoniesList = createAction(
  '[Patrimonies] reset list',
);

export interface PatrimonyDocumentListActionProps extends ListActionProps<PatrimonyDocumentSort, PatrimonyDocumentStatuses> {
  patrimonyId: number;
}

export interface CertificatListActionProps extends ListActionProps<PatrimonyCertificatSort, PatrimonyCertificatStatuses> {
  patrimonyId: number;
}

// patrimony details actions
export const loadPatrimonyDetailsAction = createAction(
  '[Patrimonies] Load patrimony details',
  props<{ patrimonyId: number; }>()
);

export const loadPatrimonyDetailsSuccessAction = createAction(
  '[Patrimonies] Load patrimony details success',
  props<{ result: PatrimonyItem }>()
);

export const loadPatrimonyDetailsFailureAction = createAction(
  '[Patrimonies] Load patrimony details failure',
  props<{ error: any; }>()
);

export const resetPatrimonyDetails = createAction(
  '[Patrimonies] reset patrimony details',
);

// patrimony favorite action
export const patrimonyFavoriteAction = createAction(
    '[Patrimoines] Patrimony favorite',
    props<{patrimonyId: number; isFavorite: boolean}>()
);

export const patrimonyFavoriteSuccessAction = createAction(
    '[Patrimoines] Patrimony favorite success',
    props<{patrimonyId: number; isFavorite: boolean}>()
);

export const patrimonyFavoriteFailureAction = createAction(
    '[Patrimoines] Patrimony favorite failure',
    props<{ error: any; }>()
);

// patrimony certificats actions
export const loadPatrimonyCertificatsAction = createAction(
  '[Patrimonies] Load patrimony certificats',
  props<CertificatListActionProps>()
);

export const loadPatrimonyCertificatsSuccessAction = createAction(
  '[Patrimonies] Load patrimony certificats success',
  props<{ result: AcPaginatedHTTPResponse<PatrimonyCertificatItem, PatrimonyCertificatMetadata> }>()
);

export const loadPatrimonyCertificatsFailureAction = createAction(
  '[Patrimonies] Load patrimony certificats failure',
  props<{ error: any; }>()
);

export const resetPatrimonyCertificats = createAction(
  '[Patrimonies] reset patrimony certificats',
);


// patrimony documents actions
export const loadPatrimonyDocumentsAction = createAction(
  '[Patrimonies] Load patrimony documents',
  props<PatrimonyDocumentListActionProps>()
);

export const loadPatrimonyDocumentsSuccessAction = createAction(
  '[Patrimonies] Load patrimony documents success',
  props<{ result: AcPaginatedHTTPResponse<PatrimonyDocument, PatrimonyDocumentMetadata> }>()
);

export const loadPatrimonyDocumentsFailureAction = createAction(
  '[Patrimonies] Load patrimony documents failure',
  props<{ error: any; }>()
);

export const resetPatrimonyDocuments = createAction(
  '[Patrimonies] reset patrimony documents',
);

// patrimony document actions
export const loadPatrimonyDocumentAction = createAction(
  '[Patrimonies] Load patrimony document',
  props<{patrimonyId: string, documentId: string}>()
);

export const loadPatrimonyDocumentSuccessAction = createAction(
  '[Patrimonies] Load patrimony document success',
  props<{ result: AcHTTPResponse<PatrimonyDocument> }>()
);

export const loadPatrimonyDocumentFailureAction = createAction(
  '[Patrimonies] Load patrimony document failure',
  props<{ error: any; }>()
);


// patrimony certificate document actions
export const loadPatrimonyCertificateDocumentAction = createAction(
  '[Patrimonies] Load patrimony Certificate document',
  props<{patrimonyId: string, certificatId: string}>()
);

export const loadPatrimonyCertificateDocumentSuccessAction = createAction(
  '[Patrimonies] Load patrimony Certificate document success',
  props<{ result: AcHTTPResponse<PatrimonyCertificatItem> }>()
);

export const loadPatrimonyCertificateDocumentFailureAction = createAction(
  '[Patrimonies] Load patrimony Certificate document failure',
  props<{ error: any; }>()
);


