import {AcHTTPResponse, AcPaginatedHTTPResponse, ListActionProps} from '../../../../core/http/http-response.models';
import { createAction, props } from '@ngrx/store';
import {
  CeeItem,
  CeeSort, CeeStatuses,
  OperationDocumentItem,
  OperationDocumentSort,
  OperationDocumentStatuses, OperationDocumentsMetadata,
  OperationItem,
  OperationSort,
  OperationStatus, CeeType
} from './operations.models';

// list actions
export const loadOperationsListAction = createAction(
  '[Operations] Load list',
  props<ListActionProps<OperationSort, OperationStatus>>()
);

export const loadOperationsListSuccessAction = createAction(
  '[Operations] Load list success',
  props<{ result: AcPaginatedHTTPResponse<OperationItem> }>()
);

export const loadOperationsListFailureAction = createAction(
  '[Operations] Load list failure',
  props<{ error: any; }>()
);

export const resetOperationsList = createAction(
  '[Operations] reset list',
);

export const resetOperationsDetails = createAction(
    '[Operations] reset details',
);



// operation favorite action
export const operationFavoriteAction = createAction(
  '[Operations] Operation favorite',
  props<{operationId: number; isFavorite: boolean}>()
);

export const operationFavoriteSuccessAction = createAction(
  '[Operations] Operation favorite success',
  props<{operationId: number; isFavorite: boolean}>()
);

export const operationFavoriteFailureAction = createAction(
  '[Operations] Operation favorite failure',
  props<{ error: any; }>()
);

export interface DocumentListActionProps extends  ListActionProps<OperationDocumentSort, OperationDocumentStatuses> {
  operationId: number;
  ceeId?: number;
}
export interface CeeListActionProps extends  ListActionProps<CeeSort, CeeStatuses> {
  operationId: number;
}


// operation documents actions
export const loadOperationDocumentsAction = createAction(
  '[Operations] Load operation documents',
  props<DocumentListActionProps>()
);

export const loadOperationDocumentsSuccessAction = createAction(
  '[Operations] Load operation documents success',
  props<{ result: AcPaginatedHTTPResponse<OperationDocumentItem, OperationDocumentsMetadata> }>()
);

export const loadOperationDocumentsFailureAction = createAction(
  '[Operations] Load operation documents failure',
  props<{ error: any; }>()
);

export const resetOperationDocuments = createAction(
  '[Operations] reset operation documents',
);

// operation documents actions
export const loadOperationCeeAction = createAction(
  '[Operations] Load operation cee',
  props<CeeListActionProps>()
);

export const loadOperationCeeSuccessAction = createAction(
  '[Operations] Load operation cee success',
  props<{ result: AcPaginatedHTTPResponse<CeeItem> }>()
);

export const loadOperationCeeFailureAction = createAction(
  '[Operations] Load operation cee failure',
  props<{ error: any; }>()
);

export const resetOperationCee = createAction(
  '[Operations] reset operation cee',
);


// operation details actions
export const loadOperationDetailsAction = createAction(
  '[Operations] Load operation details',
  props<{ operationId: number; }>()
);

export const loadOperationDetailsSuccessAction = createAction(
  '[Operations] Load operation details success',
  props<{ result: OperationItem }>()
);

export const loadOperationDetailsFailureAction = createAction(
  '[Operations] Load operation details failure',
  props<{ error: any; }>()
);

export const resetOperationDetails = createAction(
  '[Operations] reset operation details',
);


// operation document actions
export const loadOperationDocumentAction = createAction(
  '[Operations] Load operation document',
  props<{operationId: number, ceeId?: number; documentId: number}>()
);

export const loadOperationDocumentSuccessAction = createAction(
  '[Operations] Load operation document success',
  props<{ result: AcHTTPResponse<OperationDocumentItem> }>()
);

export const loadOperationDocumentFailureAction = createAction(
  '[Operations] Load operation document failure',
  props<{ error: any; }>()
);


// cee details
export const setCeeDetails = createAction(
  '[Cees] Set cee details',
  props<{ cee: CeeItem; }>()
);

export const resetCeeDetails = createAction(
    '[Operations] reset cee details',
);

export const loadCeeDetailsAction = createAction(
    '[CEE] Load CEE details',
    props<{ operationId: number, ceeId: number }>()
);

export const loadCeeDetailsSuccessAction = createAction(
    '[CEE] Load CEE details success',
    props<{ result: CeeItem }>()
);

export const loadCeeDetailsFailureAction = createAction(
    '[CEE] Load CEE details failure',
    props<{ error: any; }>()
);


// cee documents actions
export const loadCeeDocumentsAction = createAction(
  '[Cees] Load cee documents',
  props<DocumentListActionProps>()
);

export const loadCeeDocumentsSuccessAction = createAction(
  '[Cees] Load cee documents success',
  props<{ result: AcPaginatedHTTPResponse<OperationDocumentItem> }>()
);

export const loadCeeDocumentsFailureAction = createAction(
  '[Cees] Load cee documents failure',
  props<{ error: any; }>()
);




