import {createSelector} from "@ngrx/store";
import {AccountsState} from "./accounts.state";
import {selectAccountsState} from "../../../../core/core.state";


export const selectAccountCreate = createSelector(
  selectAccountsState,
  (state: AccountsState) => state.create
);

export const selectAccountCreatePeriodicVerification = createSelector(
    selectAccountCreate,
    state => state.periodicVerification
);

export const selectAccountCreateLoading = createSelector(
  selectAccountCreate,
  state => state.loading
);
export const selectAccountCreateError = createSelector(
  selectAccountCreate,
  state => state.error
);

export const selectAccountCreateEmail = createSelector(
  selectAccountCreate,
  state => state.email
);

export const selectAccountCreatedUser = createSelector(
  selectAccountCreate,
  state => state.user
);

export const selectAccountCreatedTokenDefined = createSelector(
    selectAccountCreatedUser,
    state => !!state?.token
);

export const selectAccountValidateCgu = createSelector(
    selectAccountsState,
    state => {}
);

export const selectAccountValidateCguLoading = createSelector(
    selectAccountsState,
    state => state.create.loading
);

export const selectAccountValidatePopupVersion = createSelector(
    selectAccountsState,
    state => {}
);

export const selectAccountValidatePopupVersionLoading = createSelector(
    selectAccountsState,
    state => state.create.loading
);

export const selectAccountUpdateUser = createSelector(
    selectAccountsState,
    state => {}
);

export const selectAccountUpdateUserLoading = createSelector(
    selectAccountsState,
    state => state.update.loading
);
