import { initialState } from './auth.reducer';
import { selectAuth } from './auth.selectors';
import { AuthService } from './auth.service';
import { AppState } from './../core.state';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { LocalStorageService } from '../local-storage/local-storage.service';

import {
  authLogin,
  authLogout,
  authLoginSuccess,
  authLoginFailure,
  authSetUser,
  authSetControledUser,
  authResetControledUser, userInfo, userInfoSuccess, userInfoFailure
} from './auth.actions';
import { of } from 'rxjs';
import { HttpErrorResponse } from "@angular/common/http";
import { AuthResponseErrorCodes } from "./auth.models";
import {loadAppBarNotificationsAction} from "../../features/home/notifications/core/notifications.actions";

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {


  login = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogin),
        switchMap(action => {
          return this.authService.retrieveAuthToken(action.loginRequest)
            .pipe(
              switchMap(result => {
                if (result.success) {

                  if (!result.data.user.valided) {
                    return [
                      authLoginFailure({
                        error: 'Email ou mot de passe incorrect',
                        errorCode: AuthResponseErrorCodes.INVALID_PASSWORD
                      })
                    ]
                  }
                  return [
                    authLoginSuccess({ authToken: result.data })
                  ];
                } else {
                  return [
                    authLoginFailure({
                      error: result.technical_message,
                      errorCode: AuthResponseErrorCodes.INVALID_PASSWORD // default error
                    })
                  ]
                }
              }),
              catchError((err: HttpErrorResponse) => {
                let error, errorCode, data;
                switch (err.status) {
                  case 403:
                    switch (err.error?.error_code) {
                      case AuthResponseErrorCodes.INACTIVATED:
                        errorCode = AuthResponseErrorCodes.INVALID_PASSWORD;
                        error = `Email ou mot de passe incorrect`;
                        break;
                      case AuthResponseErrorCodes.EMAIL_VALIDATION_EXPIRED:
                        errorCode = AuthResponseErrorCodes.EMAIL_VALIDATION_EXPIRED;
                        error = 'Adresse e-mail à vérifier';
                        data = err.error?.data;
                        break;
                      default:
                        errorCode = AuthResponseErrorCodes.BLOCKED;
                        error = 'Compte bloqué';
                    }
                    break;
                  case 500:
                  case 502:
                  case 504:
                    errorCode = AuthResponseErrorCodes.SERVER_ERROR;
                    error = 'Erreur du serveur';
                    break;
                  default:
                    errorCode = AuthResponseErrorCodes.INVALID_PASSWORD;
                    error = 'Email ou mot de passe incorrect';
                }
                return of(authLoginFailure({
                  error,
                  errorCode,
                  data
                }));
              }),
            )
        }),

        catchError(err => of(authLoginFailure({ error: err }))),
      ),
    { dispatch: true }
  );

  loginSuccess = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authLoginSuccess),
        withLatestFrom(this.store.pipe(select(selectAuth))),
        tap(([a, authState]) => {
            console.log('authState', authState);
          this.localStorageService.setItem(AUTH_KEY, {
            ...authState
          });
        }),
        switchMap(action => {
          return [userInfo()]
        })
      );
    },
    { dispatch: true }
  );

  loginFailure = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLoginFailure),
        tap((err) => {
          console.log(err);
        })
      ),
    { dispatch: false }
  );


  userInfo = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userInfo),
        switchMap(action => {
          return this.authService.retrieveUserInfo()
            .pipe(
              switchMap(result => {
                if (result.success) {

                  return [userInfoSuccess({
                    userInfo: result.data
                  })];
                }

                return [
                  userInfoFailure({
                    error: result.technical_message,
                  })
                ]
              })
              ,
              catchError((err: HttpErrorResponse) => {
                return of(userInfoFailure({
                  error: err.message,
                }));
              }),
            )
        }),

        catchError(err => of(userInfoFailure({ error: err }))),
      ),
    { dispatch: true }
  );


  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        tap(() => {
          this.router.navigate(['']);
          this.localStorageService.setItem(AUTH_KEY, initialState);
        })
      ),
    { dispatch: false }
  );

  setAuthUser = createEffect(
      () =>
          this.actions$.pipe(
              ofType(authSetUser),
              withLatestFrom(this.store.pipe(select(selectAuth))),
              tap(([a, authState]) => {
                  this.localStorageService.setItem(AUTH_KEY, authState);
              }),
              switchMap(([a, authState]) => {
                  return [userInfo()];
              })
         ),
      { dispatch: true }
  );

  setAuthControlledUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authSetControledUser),
        withLatestFrom(this.store.pipe(select(selectAuth))),
        tap(([a, authState]) => {
          this.localStorageService.setItem(AUTH_KEY, authState);
          this.router.navigate(['home']);
          this.store.dispatch(loadAppBarNotificationsAction())
        }),
        switchMap(([a, authState]) => {
          return [userInfo()];
        })
      ),
    { dispatch: true }
  );

  resetAuthControlledUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authResetControledUser),
        withLatestFrom(this.store.pipe(select(selectAuth))),
        tap(([a, authState]) => {
          this.localStorageService.setItem(AUTH_KEY, { ...authState, ...{ controlledUser: null } });
          this.router.navigate(['home', 'utilisateurs']);
        }),
        switchMap(([a, authState]) => {
          return [userInfo()];
        })
      ),
    { dispatch: true }
  );




  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }
}
