import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {DocumentOrigins, DocumentsService} from "../documents/documents.service";
import {NotificationService} from "../../core/notifications/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent} from "@alpes-controles/ui-lib";

@Component({
  selector: 'app-download-all-button',
  templateUrl: './download-all-button.component.html',
  styleUrls: ['./download-all-button.component.scss']
})
export class DownloadAllButtonComponent implements OnInit {
  @Input()
  documentOrigin: DocumentOrigins;

  @Input()
  params: { [key: string]: string;}

  public actionLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private documentsService: DocumentsService,
              private notificationService: NotificationService,
              private translator: TranslateService,
              private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  downloadAll() {
    this.actionLoader$.next(true);

    this.documentsService.downloadAll(
      this.documentOrigin,
      this.params
    ).subscribe(
      result => {
        const errorDialog = this.dialog.open(AlertDialogComponent, {
          //disableClose: true,
          width: '500px',
          data: {
            title: this.translator.instant('ac.global.actions.download-all.title'),
            message: '',
            description: this.translator.instant('ac.global.actions.download-all.message'),
            icon: 'email',
            action: this.translator.instant('ac.global.buttons.close')
          }
        });
        this.actionLoader$.next(false);
      },
      error => {
        this.notificationService.success(this.translator.instant('ac.global.unexpected_exception'));
        this.actionLoader$.next(false);
      },
      () => {
        this.actionLoader$.next(false);
      }
    );
  }
}
