import { TranslateService } from '@ngx-translate/core';
import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Actions, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AppState} from "../../core/core.state";
import {selectAccountValidatePopupVersionLoading} from "../../features/login/core/accounts/accounts.selectors";
import {
    accountValidatePopupVersion,
    accountValidatePopupVersionSuccess
} from "../../features/login/core/accounts/accounts.actions";

@Component({
  selector: 'app-popup-version-modal',
  templateUrl: './popup-version-modal.component.html',
  styleUrls: ['./popup-version-modal.component.scss'],
})
export class PopupVersionModalComponent implements OnInit, OnDestroy {
    form: FormGroup;
    loading$: Observable<boolean>;
    subscriptions: Subscription[] = [];

    news: string[] = [
        'ac.home.popupVersion.modal.content.item.1',
        'ac.home.popupVersion.modal.content.item.2',
        'ac.home.popupVersion.modal.content.item.3',
        'ac.home.popupVersion.modal.content.item.4'
    ];
    constructor(
        public dialogRef: MatDialogRef<PopupVersionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private dialog: MatDialog,
        private translator: TranslateService,
        private actions$: Actions,
        private store: Store<AppState>
    ) {}

    ngOnInit(): void {
        console.log("blabla");
        this.loading$ = this.store.select(selectAccountValidatePopupVersionLoading);
        this.subscriptions.push(
            this.actions$.pipe(ofType(accountValidatePopupVersionSuccess)).subscribe(_ =>
                this.dialogRef.close()
            )
        );
    }

    validate(): void {
        this.store.dispatch(accountValidatePopupVersion());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
