import { AuthState } from './auth.models';
import {
  authLogin,
  authLogout,
  authLoginSuccess,
  authLoginFailure,
  authRefreshTokenSuccess,
  authSetUser,
  authSetControledUser,
  authResetControledUser,
  userInfoSuccess, authClearError
} from './auth.actions';
import { createReducer, on, Action } from '@ngrx/store';

export const initialState: AuthState = {
  loading: false,
  token: null,
  user: null,
  menu: null,
  isAuthenticated: false,
  controlledUser: null,
  error: null,
};

const reducer = createReducer(
  initialState,
  on(authLogin, (state, action) => ({
    ...state,
    ...{
      isAuthenticated: false,
      loading: true,
      token: null,
      user: null,
      error: null,
      controlledUser: null,
    }
  })),

    on(authSetUser, (state, action) => {
        return {
            ...state,
            ...{
                user : action.user,
            }
        };
    }),

  on(authSetControledUser, (state, action) => {
    return {
      ...state,
      ...{
        controlledUser : action.controlledUser,
      }
    };
  }),

  on(authResetControledUser, (state, action) => {
    return {
      ...state,
      ...{
        controlledUser : initialState.controlledUser,
      }
    };
  }),

  on(authLoginSuccess, (state, action) => {
    const {user, ...token} = action.authToken;

    return {
      ...state,
      ...{
        isAuthenticated: true,
        loading: false,
        token,
        user,
        error: null
      }
    };

  }),

    on(authClearError, (state, action) => {
      return {
        ...state,
        ...{
          loading: false,
          error: null
        }
      };
    }),

  on(userInfoSuccess, (state, action) => {
    return {
      ...state,
      ...{
        menu: action.userInfo.menu
      }
    };

  }),

  on(authLoginFailure, (state, action) => ({
    ...state,
    ...{
      isAuthenticated: false,
      loading: false,
      token: null,
      user: null,
      error: {
        error: action.error,
        errorCode: action.errorCode,
        data: action.data
      },
    }
  })),
  on(authRefreshTokenSuccess, (state, action) => ({
    ...state,
    ...{ token: action.authToken }
  })),
  on(authLogout, state => ({
    ...state,
    ...{
      isAuthenticated: false,
      loading: false,
      user: null,
      token: null,
      error: null
    }
  }))
);

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action);
}
