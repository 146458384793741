import { authLogout, authResetControledUser } from './../../../../core/auth/auth.actions';
import {
    loadDashboardOperationsAction,
    loadDashboardOperationsSuccessAction,
    loadDashboardOperationsFailureAction,
    loadDashboardInvoicesAction,
    loadDashboardInvoicesErrorAction,
    loadDashboardInvoicesSuccessAction,
    loadDashboardDocumentsAction,
    loadDashboardDocumentsErrorAction,
    loadDashboardDocumentsSuccessAction,
    loadDashboardPatrimoniesAction,
    loadDashboardPatrimoniesSuccessAction,
    loadDashboardPatrimoniesFailureAction
} from './dashboard.actions';
import { on, createReducer, Action } from '@ngrx/store';
import { DashboardState } from './dashboard.models';
export const initialState: DashboardState = {
  documents: {
    data: null,
    loading: false,
    error: null,
  },
  invoices: {
    data: null,
    loading: false,
    error: null,
  },
  operations: {
    query: null,
    data: null,
    actionLoading: false,
    loading: false,
    error: null,
  },
    patrimoines: {
        query: null,
        data: null,
        actionLoading: false,
        loading: false,
        error: null,
    },
}

const reducer = createReducer(
  initialState,
  on(loadDashboardPatrimoniesAction, (state, action) => ({
    ...state,
    ...{
      patrimoines: {
        query: null,
        loading: true,
        actionLoading: false,
        error: null,
        data: initialState.patrimoines.data
      }
    }
  })),

  on(loadDashboardPatrimoniesSuccessAction, (state, action) => ({
    ...state,
    ...{
      patrimoines: {
        query: state.patrimoines.query,
        loading: false,
        actionLoading: false,
        error: null,
        data: action.result
      }
    }
  })),

  on(loadDashboardPatrimoniesFailureAction, (state, action) => ({
    ...state,
    ...{
      patrimoines: {
        query: initialState.patrimoines.query,
        loading: false,
        actionLoading: false,
        error: action.error,
        data: state.patrimoines.data
      }
    }
  })),

  on(loadDashboardOperationsAction, (state, action) => ({
    ...state,
    ...{
      operations: {
        query: null,
        loading: true,
        actionLoading: false,
        error: null,
        data: initialState.operations.data
      }
    }
  })),

  on(loadDashboardOperationsSuccessAction, (state, action) => ({
    ...state,
    ...{
      operations: {
        query: state.operations.query,
        loading: false,
        actionLoading: false,
        error: null,
        data: action.result
      }
    }
  })),

  on(loadDashboardOperationsFailureAction, (state, action) => ({
    ...state,
    ...{
      operations: {
        query: initialState.operations.query,
        loading: false,
        actionLoading: false,
        error: action.error,
        data: state.operations.data
      }
    }
  })),

  on(loadDashboardInvoicesAction, (state, action) => ({
    ...state,
    ...{
      invoices: {
        loading: true,
        error: null,
        data: initialState.invoices.data
      }
    }
  })),

  on(loadDashboardInvoicesErrorAction, (state, action) => ({
    ...state,
    ...{
      invoices : {
        loading: false,
        error: action.error,
        data: state.invoices.data
      }
    }
  })),

  on(loadDashboardInvoicesSuccessAction, (state, action) => ({
    ...state,
    ...{
      invoices : {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),


  // recent documents actions
  on(loadDashboardDocumentsAction, (state, action) => ({
    ...state,
    ...{
      documents: {
        loading: true,
        error: null,
        data: initialState.documents.data
      }
    }
  })),

  on(loadDashboardDocumentsErrorAction, (state, action) => ({
    ...state,
    ...{
      documents : {
        loading: false,
        error: action.error,
        data: state.documents.data
      }
    }
  })),

  on(loadDashboardDocumentsSuccessAction, (state, action) => ({
    ...state,
    ...{
      documents : {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),
  on(authLogout,authResetControledUser, (state, action) => initialState),
);


export function dashboardReducer(
  state: DashboardState | undefined,
  action: Action
): DashboardState {
  return reducer(state, action);
}

