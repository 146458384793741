import { authLogout, authResetControledUser } from './../../../../core/auth/auth.actions';
import {
  loadUsersListAction, loadUsersListSuccessAction, loadUsersListFailureAction,
  getUserAction, getUserSuccessAction, getUserFailureAction,
  createUserAction, createUserSuccessAction, createUserFailureAction,
  updateUserAction, updateUserSuccessAction, updateUserFailureAction,
  deleteUserAction, deleteUserSuccessAction, deleteUserFailureAction,
  unblockUserAction, unblockUserFailureAction, unblockUserSuccessAction
} from './users.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { UsersState } from './users.models';

export const initialState: UsersState = {
  list: {
    data: null,
    loading: false
  },
  get: {
    data: null,
    loading: false,
    error: null
  },
  create: {
    data: null,
    loading: false,
    error: null
  },
  update: {
    data: null,
    loading: false,
    error: null
  },
  delete: {
    data: null,
    loading: false,
    error: null
  },
}

const reducer = createReducer(
  initialState,

  on(loadUsersListAction, (state, action) => ({
    ...state,
    ...{
      list: {
        loading: true,
        error: null,
        data: initialState.list.data
      }
    }
  })),

  on(loadUsersListSuccessAction, (state, action) => ({
    ...state,
    ...{
      list: {
        loading: false,
        error: null,
        data: action.result
      }
    }
  })),

  on(loadUsersListFailureAction, (state, action) => ({
    ...state,
    ...{
      list: {
        loading: false,
        error: action.error,
        data: initialState.list.data
      }
    }
  })),

  on(getUserAction, (state, action) => ({
    ...state,
    ...{
      get: {
        data: null,
        loading: true,
        error: null,
      }
    }
  })),

  on(getUserSuccessAction, (state, action) => ({
    ...state,
    ...{
      get: {
        data: action.result,
        loading: false,
        error: null,
      }
    }
  })),

  on(getUserFailureAction, (state, action) => ({
    ...state,
    ...{
      get: {
        data: null,
        loading: false,
        error: action.error,
      }
    }
  })),

  on(createUserAction, (state, action) => ({
    ...state,
    ...{
      create: {
        loading: true,
        error: null,
        data: null,
      }
    }
  })),

  on(createUserSuccessAction, (state, action) => ({
    ...state,
    ...{
      create: {
        loading: false,
        error: null,
        data: null,
      }
    }
  })),

  on(createUserFailureAction, (state, action) => ({
    ...state,
    ...{
      create: {
        loading: false,
        error: action.error,
        data: null,
      }
    }
  })),

  on(updateUserAction, (state, action) => ({
    ...state,
    ...{
      update: {
        loading: true,
        error: null,
        data: null,
      }
    }
  })),

  on(updateUserSuccessAction, (state, action) => ({
    ...state,
    ...{
      update: {
        loading: false,
        error: null,
        data: null,
      }
    }
  })),

  on(updateUserFailureAction, (state, action) => ({
    ...state,
    ...{
      update: {
        loading: false,
        error: action.error,
        data: null,
      }
    }
  })),

  on(deleteUserAction, unblockUserAction, (state, action) => ({
    ...state,
    ...{
      delete: {
        loading: true,
        error: null,
        data: null,
      }
    }
  })),

  on(deleteUserSuccessAction, (state, action) => ({
    ...state,
    ...{
      delete: {
        loading: false,
        error: null,
        data: null,
      }
    }
  })),

  on(deleteUserFailureAction, unblockUserFailureAction, (state, action) => ({
    ...state,
    ...{
      delete: {
        loading: false,
        error: action.error,
        data: null,
      }
    }
  })),

  on(unblockUserSuccessAction, (state, action) => ({
    ...state,
    ...{
      list: {
        loading: false,
        error: null,
        data: {
          ...state.list.data,
          data: [...state.list.data.data.map(e => e.id !== action.result.id ? e : { ...e, blocked: false })] // unblock the user
        }
      }
    }
  })),

  on(authLogout,authResetControledUser, (state, action) => initialState),

);

export function usersReducer(
  state: UsersState | undefined,
  action: Action
): UsersState {
  return reducer(state, action);
}
