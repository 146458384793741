import { AppState } from '../core.module';


export interface LoginRequest {
  email: string;
  password: string;
}

export enum UserRoles {
  USER = 1,
  ADMIN_CLIENT = 2,
  SUPER_ADMIN = 3,
  USER_AC = 4,
  USER_LIMITED = 5
}


export interface CreatedUser {
  user_id: number;
  email: string;
  portable: string;
  token: string
}

export interface UserInfo {
  id?: number;
  email: string;
  portable: string;
  name: string;
  role: UserRoles;
  valided: boolean;
  cgu: boolean,
  popup_version: boolean,
  notification_by_email: boolean,
  created_at: Date;
  updated_at: Date;
  last_login_at: Date;
  view_contrats: boolean;
  view_factures: boolean;
}

export interface AuthToken {
  token_type: string;
  access_token: string;
  expires_in: number;
  refresh_token?: string;
  refresh_token_expires_in?: number;
}

export interface AuthResponse extends AuthToken {
  user: UserInfo;
}

export interface UserInfoResponse {
  user: UserInfo;
  menu: string[];
}


export enum AuthResponseErrorCodes {
  INACTIVATED = 'auth_account_invalided',
  EMAIL_VALIDATION_EXPIRED = 'email_validation_expired',
  BLOCKED = 'auth_account_blocked',
  INVALID_EMAIL = 'invalid_email',
  INVALID_PASSWORD = 'invalid_password',
  SERVER_ERROR = 'server_error'
}

export interface RefreshTokenRequest {
  token: string;
}

export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  token: AuthToken;
  user: UserInfo;
  menu?: string[];
  controlledUser? : UserInfo;
  error?: {
    error: any,
    errorCode: AuthResponseErrorCodes,
    data?: any
  };
}
export interface State extends AppState {
  auth: AuthState;
}
