import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectIsAuthenticated } from './auth.selectors';
import { AppState } from '../core.state';
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor(
      private store: Store<AppState>,
      private router: Router,
      private location: Location,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(select(selectIsAuthenticated)).pipe(tap(isAuth =>{
      if (!isAuth) {
        this.router.navigate(['login'], { state: {
            redirect: this.location.path(),
        }});
      }
    }));
  }
}
