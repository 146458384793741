import { authLogout, authResetControledUser } from './../../../../core/auth/auth.actions';
import { loadNotificationsAction, loadNotificationsSuccessAction, loadNotificationsErrorAction, setNotificationSeenAction, setNotificationSeenErrorAction, setNotificationSeenSuccessAction, loadAppBarNotificationsSuccessAction } from './notifications.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { NotificationsState } from "./notifications.models";

export const initialState: NotificationsState = {
  data : null,
  loading: false,
  error: null,
  appBarNotifications: null,
}

const reducer = createReducer(
  initialState,
  on(loadNotificationsAction, (state, action) => ({
    ...state,
    ...{
      data : initialState.data,
      loading: true,
      error: null,
    }
  })),

  on(loadNotificationsSuccessAction, (state, action) => ({
    ...state,
    ...{
      data : action.result,
      loading: false,
      error: null,
    }
  })),

  on(loadNotificationsErrorAction, (state, action) => ({
    ...state,
    ...{
      data : initialState.data,
      loading: false,
      error: action.error,
    }
  })),

  on(loadAppBarNotificationsSuccessAction, (state, action) => ({
    ...state,
    ...{
      data : state.data,
      loading: state.loading,
      error: state.error,
      appBarNotifications: action.result,
    }
  })),

  on(setNotificationSeenAction, (state, action) => ({
    ...state,
    ...{
      loading: true,
      error: null,
    }
  })),

  on(setNotificationSeenErrorAction, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: action.error,
    }
  })),

  on(setNotificationSeenSuccessAction, (state, action) => ({
    ...state,
    ...{
      loading: false,
      error: null,
    }
  })),

  on(authLogout,authResetControledUser, (state, action) => initialState),

);

export function notificationsReducer(
  state: NotificationsState | undefined,
  action: Action
): NotificationsState {
  return reducer(state, action);
}
