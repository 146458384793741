import {trigger, style, animate, transition} from '@angular/animations';

export const horizontalCollapse = trigger('horizontalCollapse', [
    transition(':enter', [
        style({ width: 0, opacity: 0 }),
        animate('200ms ease-in-out', style({ width: '*', opacity: 1 }))
    ]),
    transition(':leave', [
        style({ width: '*', opacity: 1 }),
        animate('200ms ease-in-out', style({ width: 0, opacity: 0 }))
    ])
]);

export const verticalCollapse = trigger('verticalCollapse', [
    transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('200ms ease-in-out', style({ height: '*', opacity: 1 }))
    ]),
    transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('200ms ease-in-out', style({ height: 0, opacity: 0 }))
    ])
]);
