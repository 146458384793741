import {OperationDocumentItem, OperationItem} from './../../operations/core/operations.models';
import { InvoiceItem } from './../../invoices/core/invoices.models';
import { createAction, props } from "@ngrx/store";
import { AcPaginatedHTTPResponse } from "../../../../core/http/http-response.models";
import {BaseDocument, BaseDocumentStatuses} from "../../../../shared/documents/documents.models";
import {UserDocument} from "./dashboard.models";
import {PatrimonyItem} from "../../patrimony/core/patrimonies.models";

export const loadDashboardDocumentsAction = createAction('[Dashboard] Load Documents');
export const loadDashboardDocumentsSuccessAction = createAction('[Dashboard] Load Documents success', props<{ result: AcPaginatedHTTPResponse<UserDocument> }>());
export const loadDashboardDocumentsErrorAction = createAction('[Dashboard] Load Documents error', props<{ error: any }>());

export const loadDashboardInvoicesAction = createAction('[Dashboard] Load Invoices');
export const loadDashboardInvoicesSuccessAction = createAction('[Dashboard] Load Invoices success', props<{ result: AcPaginatedHTTPResponse<InvoiceItem> }>());
export const loadDashboardInvoicesErrorAction = createAction('[Dashboard] Load Invoices error', props<{ error: any }>());

export const loadDashboardOperationsAction = createAction('[Dashboard] Load Operations',);
export const loadDashboardOperationsSuccessAction = createAction('[Dashboard] Load Operations success',props<{ result: AcPaginatedHTTPResponse<OperationItem> }>());
export const loadDashboardOperationsFailureAction = createAction('[Dashboard] Load Operations failure',props<{ error: any; }>());

export const loadDashboardPatrimoniesAction = createAction('[Dashboard] Load Patrimoines',);
export const loadDashboardPatrimoniesSuccessAction = createAction('[Dashboard] Load Patrimoines success',props<{ result: AcPaginatedHTTPResponse<PatrimonyItem> }>());
export const loadDashboardPatrimoniesFailureAction = createAction('[Dashboard] Load Patrimoines failure',props<{ error: any; }>());
