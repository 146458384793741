<button mat-raised-button [matMenuTriggerFor]="statusMenu" class="ac-select status-menu-trigger">
    <mat-icon *ngIf="!status || !status.icon" class="material-icons-outlined">info</mat-icon>
    <mat-icon *ngIf="status && status.icon" class="material-icons-outlined">{{ status.icon }}</mat-icon>
    &nbsp;
    <span *ngIf="!status">{{ 'ac.global.filters.statuses' | translate | uppercase }}</span>
    <span *ngIf="status">{{ status.label | translate | uppercase }}</span>&nbsp;
    <mat-icon>expand_more</mat-icon>
</button>
<mat-menu #statusMenu="matMenu" class="status-menu">
    <div
        mat-menu-item
        class="status-menu-item"
        [ngClass]="{ 'icon-btn': showIcons }"
        (click)="onStatusClick(null)"
    >
        -- Tous --
    </div>
    <div
        mat-menu-item
        class="status-menu-item"
        *ngFor="let status of getStatusList()"
        [ngClass]="{ 'icon-btn': showIcons }"
        (click)="onStatusClick(status)"
    >
        <button *ngIf="status.icon" mat-mini-fab class="{{ status.color }}">
            <mat-icon class="material-icons-outlined">{{ status.icon }}</mat-icon>
        </button>
        <span *ngIf="!status.icon">{{ status.label | translate }}</span>
    </div>
</mat-menu>
