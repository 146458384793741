import {AcHTTPResponse, AcPaginatedHTTPResponse, ListActionProps} from "../../../../core/http/http-response.models";
import { createAction, props } from '@ngrx/store';
import { TiersItem, TiersSort } from './tiers.models';

export const tiersListAction = createAction(
  '[Tiers] list',
  props<ListActionProps<TiersSort, null>>()
);

export const tiersListSucessAction = createAction(
    '[Tiers] list success',
    props<{ result: AcPaginatedHTTPResponse<TiersItem> }>()
);

export const tiersListFailureAction = createAction(
    '[Tiers] list failure',
    props<{ error: any }>()
);
