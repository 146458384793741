import { createReducer, on, Action } from '@ngrx/store';
import { sendContactFormAction, sendContactFormSuccessAction, sendContactFormFailureAction } from './contact-form.actions';
import { ContactFormState } from './contact-form.models';

export const initialState: ContactFormState = {
  send: {
    loading: false,
    error: null,
  }
}

const reducer = createReducer(
  initialState,
  on(sendContactFormAction, (state, action) => {
      return ({
          ...state,
          ...{
              send: {
                  loading: true,
                  error: null,
              }
          }
      })
  }),
  on(sendContactFormSuccessAction, (state, action) => ({
    ...state,
    ...{
        send: {
        loading: false,
        error: null,
      }
    }
  })),
  on(sendContactFormFailureAction, (state, action) => ({
    ...state,
    ...{
        send: {
        loading: false,
        error: action.error,
      }
    }
  })),

);

export function contactFormReducer(
  state: ContactFormState | undefined,
  action: Action
): ContactFormState {
  return reducer(state, action);
}
