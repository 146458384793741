import {PdfViewerModule} from 'ng2-pdf-viewer';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'

import {TranslateModule} from '@ngx-translate/core';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';

import {MatSliderModule,} from '@angular/material/slider';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from "@angular/material/radio";

import {UiLibModule} from '@alpes-controles/ui-lib';

import {MatNativeDateModule} from "@angular/material/core";
import {MatSortModule} from '@angular/material/sort';

import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {DocumentViewerComponent} from './documents/document-viewer/document-viewer.component';
import {DownloadAllButtonComponent} from './download-all-button/download-all-button.component';
import {ModalComponent} from "./modal/modal.component";
import {PillComponent} from "./pill/pill.component";
import {ModelPickerComponent} from "./model-picker/model-picker.component";
import {ChipInputComponent} from "./chip-input/chip-input.component";
import {StatusSelectComponent} from "./status-select/status-select.component";
import {PopoverComponent} from "./popover/popover.component";
import {CguContentComponent} from "./cgu-content/cgu-content.component";
import {ConfirmDialogComponent} from './dialog/confirm-dialog.component';
import {StylePaginatorDirective} from './paginator/style-paginator.directive';
import {AcMatPaginatorIntl} from "./paginator/ac-mat-paginator-intl";
import {KeepHtmlPipe} from './pipes/keep-html.pipe';
import {AcDatePipe} from './pipes/ac-date.pipe';
import {PopupVersionModalComponent} from "./popup-version/popup-version-modal.component";

@NgModule({
    imports: [
        PdfViewerModule,
        MatSortModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MatPaginatorModule,
        TranslateModule,
        MatDialogModule,
        MatTableModule,
        MatButtonModule,
        MatBadgeModule,
        MatToolbarModule,
        MatSelectModule,
        MatTabsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatCardModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatStepperModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxSkeletonLoaderModule,
        UiLibModule,
    ],
    declarations: [
        ConfirmDialogComponent,
        ModalComponent,
        PillComponent,
        StylePaginatorDirective,
        KeepHtmlPipe,
        AcDatePipe,
        DocumentViewerComponent,
        DownloadAllButtonComponent,
        ModelPickerComponent,
        ChipInputComponent,
        StatusSelectComponent,
        PopoverComponent,
        CguContentComponent,
      PopupVersionModalComponent
    ],
    exports: [
        MatSortModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatPaginatorModule,
        MatTableModule,
        MatButtonModule,
        MatBadgeModule,
        MatMenuModule,
        MatTabsModule,
        MatChipsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatCardModule,
        MatSidenavModule,
        MatListModule,
        MatSelectModule,
        MatToolbarModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatSliderModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatDialogModule,
        MatStepperModule,
        MatRadioModule,
        NgxSkeletonLoaderModule,
        UiLibModule,

        StylePaginatorDirective,
        AcDatePipe,
        ConfirmDialogComponent,
        ModalComponent,
        NgxMaskDirective,
        NgxMaskPipe,
        DownloadAllButtonComponent,
        PillComponent,
        ModelPickerComponent,
        ChipInputComponent,
        StatusSelectComponent,
        PopoverComponent,
        CguContentComponent,
        DocumentViewerComponent,
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: AcMatPaginatorIntl },
        provideNgxMask(),
    ]
})
export class SharedModule { }
