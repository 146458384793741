export const environment = {
  production: false,
  i18nPrefix: '',
  appName: window['env']['APP_NAME'] || 'Alpes Controles [PREPROD]',
  api: {
    BASE_URL: window['env']['API_BASE_URL'] || 'https://api-espace-client.build.bactech.info/',
    API_VERSION: window['env']['API_VERSION'] || 'v1',
    endpoints: {
      users : {
        LIST : '/espace-client/users',
        DETAILS : '/espace-client/users/{userID}',
      }
    }
  }
};
