import { environment } from 'src/environments/environment';
import {DocumentOrigins} from "../../shared/documents/documents.service";
export const api = {
  endpoints: {
    auth : {
      LOGIN : '/auth/login',
      REFRESH_TOKEN: '/auth/refresh',
      USER_INFO: '/auth/user-infos',
      LOGOUT: '/auth/logout',
      GET_OTP : '/espace-client/auth/otp',
      CHECK_OTP : '/espace-client/auth/otp/check'
    },
    account: {
      VERIFY: '/account/email-check',
      CREATE: '/account/create',
      UPDATE: '/account/update',
      VALIDATE: '/account/validate',
      VERIFY_CODE: '/account/verify',
      FORGOT_PASSWORD: '/account/forgot-password',
      RESET_PASSWORD: '/account/reset-password',
      VALIDATE_CGU: '/account/validate-cgu',
      VALIDATE_POPUPVERSION: '/account/validate-popup-version',
    },
    contracts : {
      LIST : '/contrats',
    },
    avenants : {
      DETAILS : '/avenants/{avenantId}',
    },
    invoices : {
      LIST : '/factures',
    },
    notifications : {
      LIST : '/notifications',
    },
    patrimonies : {
      LIST : '/patrimoines',
      DETAILS : '/patrimoines/{patrimonyID}',
      DOCUMENTS : '/patrimoines/{patrimonyID}/documents',
      FAVORITE : '/patrimoines/{patrimonyID}/favoris',
      CERTIFICATS : '/patrimoines/{patrimonyID}/certifications',
      EXPORT_OBSERVATIONS : `${environment.api.BASE_URL}${environment.api.API_VERSION}/patrimoines/export/observations`,
      EXPORT_OBSERVATIONS_YEARS : `${environment.api.BASE_URL}${environment.api.API_VERSION}/patrimoines/export/observations/annees`,
      EXPORT_OBSERVATIONS_NATURES : `${environment.api.BASE_URL}${environment.api.API_VERSION}/patrimoines/export/observations/natures`,
    },
    users : {
      LIST : '/admin/users',
      GET : '/admin/users/{userID}',
      CREATE : '/admin/users',
      UPDATE : '/admin/users/{userID}',
      DELETE : '/admin/users/{userID}',
      NOTIFY : '/admin/users/{userID}/notify',
      UNBLOCK : '/admin/users/{userID}/unlock',
    },
    myUsers: {
      LIST: '/users',
      GET: '/users/{userID}',
      CREATE: '/users',
      UPDATE: '/users/{userID}',
      DELETE: '/users/{userID}'
    },
    operations : {
      LIST : '/operations',
      FAVORITE : '/operations/{operationID}/favoris',
      DETAILS : '/operations/{operationID}',
      DOCUMENTS : '/operations/{operationID}/documents',
      CEE : '/operations/{operationID}/cee',
      CEE_DOCUMENTS : '/operations/{operationID}/cee/{ceeId}/documents',
      CEE_DETAILS : '/operations/{operationID}/cee/{ceeId}',
      CEE_SYNTHESE: `${environment.api.BASE_URL}${environment.api.API_VERSION}/operations/{operationID}/cee/{ceeId}/synthese`
    },
    documents : {
      LIST: '/documents',
      USER_DOCUMENTS: '/documents/user',
      DOCUMENT_DETAILS: `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents/{{id_file}}/details`,
      FICHIER_CLASSEUR: `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents/{{id_file}}/fichier?origin={{origin}}&fichier={{fichier}}`,
      FICHIER_DOSSIER_CLASSEUR: `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents/{{id_file}}/dossier/fichier?origin={{origin}}&fichier={{fichier}}&dossier={{dossier}}`,
      INVOICE : `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents?document_ids={{id_files}}&origin=factures`,
      CONTRACT : `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents?document_ids={{id_files}}&origin=contrats`,
      AVENANT : `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents?document_ids={{id_files}}&origin=avenants`,
      ACCORD_CONTRAT : `${environment.api.BASE_URL}${environment.api.API_VERSION}/contrats/accord/{{accord_id}}`,
      ACCORD_AVENANT : `${environment.api.BASE_URL}${environment.api.API_VERSION}/avenants/{{avenant_id}}/accord`,
      DOWNLOAD_SELECTED : `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents?document_ids={{id_files}}&origin={{origin}}&disposition=attachment`,
      DOWNLOAD_ALL : `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents?document_ids=all&origin={{origin}}`,
      PATRIMONY : `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents?document_ids={{id_files}}&origin=documents_interventions&disposition=attachment`,
      CEE : `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents?document_ids={{id_files}}&origin=documents_cee`,
      OPERATION : `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents?document_ids={{id_files}}&origin=documents_operations`,
      CERTIFICATION : `${environment.api.BASE_URL}${environment.api.API_VERSION}/documents?document_ids={{id_files}}&origin=documents_certification`,
    },
    search: {
      SEARCH: '/search'
    },
    app: {
      config: '/app/config'
    },
    tiers : {
      LIST : '/admin/tiers',
    },
    contactForms : {
      SEND : '/contact-form/send',
    },
    enqueteSatisfaction: {
      getInfos: '/enquetes-satisfaction/token/{token}',
      saveReponses: '/enquetes-satisfaction/token/{token}',
    }
  }
};
