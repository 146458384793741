import {createAction, props} from "@ngrx/store";
import {AcHTTPResponse, AcPaginatedHTTPResponse, ListActionProps} from "../../../../core/http/http-response.models";
import {UserItem} from "../../administration/core/users.models";
import {MyUsersSort, NewUserDTO} from "./my-users.models";

export const loadMyUsersListAction = createAction(
    '[My users] Load list',
    props<ListActionProps<MyUsersSort, null>>()
);
export const loadMyUsersListSuccessAction = createAction(
    '[My users] Load list success',
    props<{ result: AcPaginatedHTTPResponse<UserItem> }>()
);

export const loadMyUsersListFailureAction = createAction(
    '[My users] Load list failure',
    props<{ error: any }>()
);

export const getMyUserAction = createAction(
    '[My users] Get user',
    props<{ userId: number }>()
);

export const getMyUserSuccessAction = createAction(
    '[My users] Get user success',
    props<{ result: AcHTTPResponse<UserItem> }>()
);

export const getMyUserFailureAction = createAction(
    '[My users] Get user failure',
    props<{ error: any }>()
);

export const deleteMyUserAction = createAction(
    '[My users] Delete user',
    props<{ userId: number }>()
);

export const deleteMyUserSuccessAction = createAction(
    '[My users] Delete user success'
);

export const deleteMyUserFailureAction = createAction(
    '[My users] Delete user failure',
    props<{ error: any }>()
);

export const addMyUserAction = createAction(
    '[My users] Create user',
    props<NewUserDTO>()
);

export const addMyUserSuccessAction = createAction(
    '[My users] Create user success'
);

export const addMyUserFailureAction = createAction(
    '[My users] Create user failure',
    props<{ error: any }>()
);

export const updateMyUserAction = createAction(
    '[My users] Update user',
    props<{ userId: number, data: NewUserDTO }>()
);

export const updateMyUserSuccessAction = createAction(
    '[My users] Update user success'
);

export const updateMyUserFailureAction = createAction(
    '[My users] Update user failure',
    props<{ error: any }>()
);
