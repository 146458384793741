import {LoginRequest, AuthToken, AuthResponseErrorCodes, AuthResponse, UserInfo, UserInfoResponse} from './auth.models';
import { createAction, props } from '@ngrx/store';

export const authLogin = createAction('[Auth] Login',
  props<{ loginRequest: LoginRequest }>()
);

export const authSetUser = createAction('[Auth] Set User',
  props<{ user: UserInfo }>()
);

export const authSetControledUser = createAction('[Auth] Set Controlled User',
    props<{ controlledUser: UserInfo }>()
);

export const authResetControledUser = createAction('[Auth] Reset Controlled User');

export const authLoginSuccess = createAction(
  '[Auth] Login Success',
  props<{ authToken: AuthResponse }>()
);

export const authLoginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any, errorCode?: AuthResponseErrorCodes, data?: any }>()
);

export const authClearError = createAction(
    '[Auth] Clear error'
);

export const authRefreshTokenSuccess = createAction(
  '[Auth] Login Refresh Token Success',
  props<{ authToken: AuthToken }>()
);


export const userInfo = createAction('[Auth] User info',
);

export const userInfoSuccess = createAction(
  '[Auth] User info Success',
  props<{ userInfo: UserInfoResponse }>()
);

export const userInfoFailure = createAction(
  '[Auth] User info Failure',
  props<{ error: any; }>()
);

export const authLogout = createAction('[Auth] Logout');
