import {createAction, props} from "@ngrx/store";
import {AuthResponseErrorCodes, CreatedUser, UserInfo} from "../../../../core/auth/auth.models";
import {AccountResponseErrorCodes, CreateData, ResetPasswordData, UpdateData} from "./accounts.models";


// account veridy actions
export const accountVerify = createAction('[Account] Verify',
  props<{ email: string }>()
);

export const accountVerifySuccess = createAction(
  '[Account] Verify Success',
  props<{ email: string; }>()
);

export const accountVerifyFailure = createAction(
  '[Account] Verify Failure',
  props<{ error: any, errorCode?: AccountResponseErrorCodes }>()
);


// account create actions
export const accountCreate = createAction('[Account] Create',
  props<{ data: CreateData }>()
);

export const accountCreateSuccess = createAction(
  '[Account] Create Success',
  props<{ user: CreatedUser; }>()
);

export const accountCreateFailure = createAction(
  '[Account] Create Failure',
  props<{ error: any, errorCode?: AccountResponseErrorCodes }>()
);

// Email verification
export const accountEmailVerificationNeeded = createAction(
    '[Account] Email verification needed',
    props<{ user: CreatedUser; }>()
);


// account send code actions
export const accountSendCode = createAction('[Account] Send Code',
  props<{ userId: number, token: string, isEmail: boolean }>()
);

export const accountSendCodeSuccess = createAction(
  '[Account] Send Code Success',
);

export const accountSendCodeFailure = createAction(
  '[Account] Send Code Failure',
  props<{ error: any, errorCode?: AccountResponseErrorCodes }>()
);


// account verify code
export const accountVerifyCode = createAction('[Account] Verify Code',
  props<{ userId: number, token: string, code: string }>()
);

export const accountVerifyCodeSuccess = createAction(
  '[Account] Verify Code Success',
);

export const accountVerifyCodeFailure = createAction(
  '[Account] Verify Code Failure',
  props<{ error: any, errorCode?: AccountResponseErrorCodes }>()
);



// account forgot password actions
export const accountForgotPassword = createAction('[Account] Forgot Password',
  props<{ email: string }>()
);

export const accountForgotPasswordSuccess = createAction(
  '[Account] Forgot Password Success',
  // props<{ }>()
);

export const accountForgotPasswordFailure = createAction(
  '[Account] Forgot Password Failure',
  props<{ error: any, errorCode?: AccountResponseErrorCodes }>()
);


// reset password actions
export const accountResetPassword = createAction('[Account] Reset Password',
  props<{ data: ResetPasswordData }>()
);

export const accountResetPasswordSuccess = createAction(
  '[Account] Reset Password Success',
  // props<{  }>()
);

export const accountResetPasswordFailure = createAction(
  '[Account] Reset Password Failure',
  props<{ error: any, errorCode?: AccountResponseErrorCodes }>()
);


export  const accountResetError = createAction(
  '[Account] Reset error'
)

// account validate CGU actions
export const accountValidateCgu = createAction('[Account] Validate CGU');

export const accountValidateCguSuccess = createAction('[Account] Validate CGU Success');

export const accountValidateCguFailure = createAction(
    '[Account] Validate CGU Failure',
    props<{ error: any, errorCode?: AccountResponseErrorCodes }>()
);

// account validate popup version actions
export const accountValidatePopupVersion = createAction('[Account] Validate Popup Version');

export const accountValidatePopupVersionSuccess = createAction('[Account] Validate Popup Version Success');

export const accountValidatePopupVersionFailure = createAction(
    '[Account] Validate Popup Version Failure',
    props<{ error: any, errorCode?: AccountResponseErrorCodes }>()
);

// account update actions
export const accountUpdateUser = createAction(
    '[Account] Update user',
    props<{ data: UpdateData }>()
);

export const accountUpdateUserSuccess = createAction('[Account] update user Success');

export const accountUpdateUserFailure = createAction(
    '[Account] update user failure',
    props<{ error: any, errorCode?: AccountResponseErrorCodes }>()
);
